import React, { useContext } from "react";

import { CollectionsContext } from "../../contexts/CollectionsContext";
import { IContentModuleProductListingFields } from "../../types/generated/contentful";
import EcommerceProductList from "../ecommerce/EcommerceProductList";

const ProductListing: React.FC<IContentModuleProductListingFields> = ({
  heading,
  collectionId,
}) => {
  const { collections, getProductsByCollectionId, loading } =
    useContext(CollectionsContext);

  const products = getProductsByCollectionId(collections, collectionId);

  if (loading || !products) {
    return <></>;
  }

  return <EcommerceProductList title={heading} products={products} />;
};

export default ProductListing;

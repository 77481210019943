import React from "react";

import { PictureProps } from "./Picture";

const PictureSvg: React.FC<PictureProps> = ({
  title,
  description,
  url,
  cssClass,
}) => (
  <picture data-testid="picture">
    <img
      className={cssClass}
      src={url}
      alt={description}
      title={title}
      data-testid="picture-image"
    />
  </picture>
);

export default PictureSvg;

import * as typeformEmbed from "@typeform/embed";
import classNames, { Mapping } from "classnames";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { CookieConsentContext } from "../../contexts/CookieConsentContext";
import { IContentModuleEmbeddedContentFields } from "../../types/generated/contentful";
import {
  getMissingCookiePermissions,
  parseRequiredCookiePermissions,
} from "../../utils/cookie-consent";
import px2rem from "../../utils/px2rem";
import CookiePermissionsMissing from "../CookiePermissionsMissing";

export const TEST_ID_EMBEDDED_CONTENT_CONTAINER = "embedded-content-container";
export const TEST_ID_EMBEDDED_CONTENT_DIV = "embedded-content-div";
export const TEST_ID_EMBEDDED_CONTENT_IFRAME = "embedded-content-iframe";
export const TEST_ID_EMBEDDED_CONTENT_TYPEFORM = "embedded-content-typeform";

type EmbeddedContentProps = IContentModuleEmbeddedContentFields & {
  makeWidget?: typeformEmbed.MakeWidgetFn;
};

const EmbeddedContent: React.FC<EmbeddedContentProps> = ({
  cookiePermissions,
  dataAttributes,
  desktopWidth,
  height,
  horizontalPadding,
  horizontalPaddingMobile,
  id,
  makeWidget,
  minimumHeight,
  mobileWidth,
  title,
  type,
  url,
  verticalMargin,
}) => {
  const { givenPermissions } = useContext(CookieConsentContext);
  const requiredPermissions = useMemo(() => {
    return parseRequiredCookiePermissions(cookiePermissions);
  }, [cookiePermissions]);
  const missingPermissions = useMemo(() => {
    return getMissingCookiePermissions(requiredPermissions, givenPermissions);
  }, [givenPermissions, requiredPermissions]);
  const typeformContainerRef = useRef<HTMLDivElement | null>(null);
  const makeWidgetFn = makeWidget || typeformEmbed.makeWidget;

  useEffect(() => {
    if (
      missingPermissions.length === 0 &&
      type === "typeform" &&
      url !== undefined &&
      typeformContainerRef.current
    ) {
      makeWidgetFn(typeformContainerRef.current, url, {
        hideFooter: true,
        hideHeaders: true,
        opacity: 100,
      });
    }
  }, [missingPermissions, makeWidgetFn, type, url]);

  const getElement = useCallback(() => {
    const classes: Mapping = {
      "h-100": height === "100%",
      "h-auto": height === "auto",
      ph0: horizontalPaddingMobile === "none",
      ph3: horizontalPaddingMobile === "normal",
      ph4: horizontalPaddingMobile === "big",
      "ph0-ns": horizontalPadding === "none",
      "ph3-ns": horizontalPadding === "normal",
      "ph4-ns": horizontalPadding === "big",
      "w-100": mobileWidth === "100%",
      "w-50": mobileWidth === "50%",
      "w-auto": mobileWidth === "auto",
      "w-100-ns": desktopWidth === "100%",
      "w-50-ns": desktopWidth === "50%",
      "w-auto-ns": desktopWidth === "auto",
    };

    const minHeight = minimumHeight ? px2rem(minimumHeight) : undefined;
    const style: React.CSSProperties = {
      height: height === "use-minimum-height" ? minHeight : undefined,
      minHeight,
      boxSizing: "border-box",
    };

    const dataAttrs: { [key: string]: string } = {};

    if (dataAttributes !== undefined) {
      for (const key of Object.keys(dataAttributes)) {
        dataAttrs[`data-${key}`] = dataAttributes[key];
      }
    }

    switch (type) {
      case "div": {
        return (
          <div
            className={classNames(classes)}
            id={id}
            style={style}
            {...dataAttrs}
            data-testid={TEST_ID_EMBEDDED_CONTENT_DIV}
          />
        );
      }
      case "iframe": {
        return (
          <iframe
            id={id}
            src={url}
            className={classNames("b--none", classes)}
            title={title}
            frameBorder={0}
            style={style}
            {...dataAttrs}
            data-testid={TEST_ID_EMBEDDED_CONTENT_IFRAME}
          />
        );
      }
      case "typeform": {
        return (
          <div
            id={id}
            ref={typeformContainerRef}
            className={classNames(classes)}
            style={style}
            {...dataAttrs}
            data-testid={TEST_ID_EMBEDDED_CONTENT_TYPEFORM}
          />
        );
      }
      default: {
        return <React.Fragment />;
      }
    }
  }, [
    dataAttributes,
    desktopWidth,
    height,
    horizontalPadding,
    horizontalPaddingMobile,
    id,
    minimumHeight,
    mobileWidth,
    title,
    type,
    url,
  ]);

  return (
    <div
      className={classNames("w-100", "flex", "flex-column", "items-center", {
        "h-100": height === "100%",
        "h-auto": height === "auto",
        mv0: verticalMargin === "none",
        mv3: verticalMargin === "normal",
        "mv4-ns": verticalMargin === "normal",
        mv4: verticalMargin === "big",
        "mv5-ns": verticalMargin === "big",
      })}
      data-testid={TEST_ID_EMBEDDED_CONTENT_CONTAINER}
    >
      {missingPermissions.length === 0 ? (
        getElement()
      ) : (
        <CookiePermissionsMissing missingPermissions={missingPermissions} />
      )}
    </div>
  );
};

export default EmbeddedContent;

import classnames from "classnames";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useMemo } from "react";
import ReactCalendar, { CalendarTileProperties } from "react-calendar";
import { FormattedMessage, useIntl } from "react-intl";

import IconArrowLeft from "../../public/svg/icon-arrow-left.svg";
import IconArrowRight from "../../public/svg/icon-arrow-right.svg";
import { ThemeContext } from "../contexts/ThemeContext";
import { CalendarRowWithDateTime } from "../types/calendar";

export const TEST_ID_OPENING_HOURS_CALENDAR_LEGEND =
  "opening-hours-calendar-legend";

interface OpeningHoursCalendarProps {
  selectedDate: DateTime;
  today: DateTime;
  calendarRows: CalendarRowWithDateTime[];
  handleDaySelect: (date: DateTime) => void;
}

const OpeningHoursCalendar: React.FC<OpeningHoursCalendarProps> = ({
  selectedDate,
  today,
  calendarRows,
  handleDaySelect,
}) => {
  const { styles } = useContext(ThemeContext);
  const { locale, formatMessage } = useIntl();
  const legend = useMemo(() => {
    const selectedMonthDates = calendarRows.filter(
      ({ Pvm }) => Pvm >= today && Pvm.hasSame(selectedDate, "month"),
    );

    let parkClosed = false;
    let ridesOpen = false;
    let specialEvents = false;

    for (const {
      Särkänniemi,
      Huvipuisto,
      Tiedote,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Tiedote_ENG,
    } of selectedMonthDates) {
      if (parkClosed && ridesOpen && specialEvents) {
        break;
      }

      if (Särkänniemi === "Suljettu") {
        parkClosed = true;
      }

      if (Huvipuisto !== "Suljettu") {
        ridesOpen = true;
      }

      if (Tiedote !== undefined && Tiedote_ENG !== undefined) {
        specialEvents = true;
      }
    }
    return parkClosed || ridesOpen || specialEvents
      ? {
          parkClosed,
          ridesOpen,
          specialEvents,
        }
      : null;
  }, [calendarRows, selectedDate, today]);
  const minDate = today;
  const maxDate = calendarRows.reduce((a, b) => (a.Pvm > b.Pvm ? a : b)).Pvm;

  const tileContent = useCallback(
    ({ date }: CalendarTileProperties): JSX.Element => {
      const _calendarRows = calendarRows.find(row =>
        row.Pvm.equals(DateTime.fromJSDate(date)),
      );
      let legend: JSX.Element = <></>;

      if (!_calendarRows || _calendarRows.Pvm < today) {
        return <></>;
      } else if (_calendarRows.Särkänniemi === "Suljettu") {
        legend = (
          <span
            aria-label={formatMessage({ id: "openingHours.parkIsClosed" })}
          ></span>
        );
      } else if (_calendarRows.Huvipuisto !== "Suljettu") {
        legend = (
          <span
            aria-label={formatMessage({ id: "openingHours.ridesAreOpen" })}
          ></span>
        );
      }

      if (_calendarRows.Tiedote && _calendarRows.Tiedote_ENG) {
        legend = (
          <>
            {legend}
            <span
              className="bg-pink br-100 dib"
              aria-label={formatMessage({ id: "openingHours.specialEvents" })}
              style={{ width: "0.5rem", height: "0.5rem" }}
            ></span>
          </>
        );
      }

      return legend;
    },
    [formatMessage, calendarRows, today],
  );

  const tileClassName = useCallback(
    ({ date }: CalendarTileProperties): string => {
      const _calendarRows = calendarRows.find(openingHour =>
        openingHour.Pvm.equals(DateTime.fromJSDate(date)),
      );

      const isTouch = Boolean("ontouchstart" in window);
      const hover = !isTouch ? "hover" : "";

      if (!_calendarRows || _calendarRows.Pvm < today) {
        return hover;
      } else if (_calendarRows.Särkänniemi === "Suljettu") {
        return classnames(hover, "closed");
      } else if (_calendarRows.Huvipuisto !== "Suljettu") {
        return classnames(hover, "open");
      } else {
        return hover;
      }
    },
    [calendarRows, today],
  );

  const tileDisabled = useCallback(
    ({ date }: CalendarTileProperties): boolean => {
      const _calendarRows = calendarRows.find(openingHour =>
        openingHour.Pvm.equals(DateTime.fromJSDate(date)),
      );

      return !!_calendarRows && _calendarRows.Pvm < today;
    },
    [calendarRows, today],
  );

  function handleTodayClick(): void {
    handleDaySelect(today);
  }

  const onClickDay = useCallback(
    (date: Date): void => {
      handleDaySelect(DateTime.fromJSDate(date));
    },
    [handleDaySelect],
  );

  const changeToNextMonth = useCallback(() => {
    const newDate = selectedDate.plus({ month: 1 });
    if (newDate <= maxDate || newDate.hasSame(maxDate, "month")) {
      handleDaySelect(newDate);
    }
  }, [handleDaySelect, maxDate, selectedDate]);

  const changeToPreviousMonth = useCallback(() => {
    const newDate = selectedDate.minus({ month: 1 });
    if (newDate >= minDate || newDate.hasSame(minDate, "month")) {
      handleDaySelect(newDate);
    }
  }, [handleDaySelect, minDate, selectedDate]);

  const onPreviousPress = useCallback(
    (event: React.KeyboardEvent<HTMLSpanElement>) => {
      if (event.keyCode === 13) {
        changeToPreviousMonth();
      }
    },
    [changeToPreviousMonth],
  );

  const onNextPress = useCallback(
    (event: React.KeyboardEvent<HTMLSpanElement>) => {
      if (event.keyCode === 13) {
        changeToNextMonth();
      }
    },
    [changeToNextMonth],
  );

  return (
    <div>
      <ReactCalendar
        activeStartDate={selectedDate.startOf("month").toJSDate()}
        defaultView="month"
        minDetail="month"
        locale={locale}
        tileClassName={tileClassName}
        tileContent={tileContent}
        tileDisabled={tileDisabled}
        next2Label={null}
        prev2Label={null}
        nextLabel={
          <span
            className="flex items-center justify-center icon-wrapper-large svg-icon stroke-current-color fill-transparent pointer hover-pink ph3-l outline-0"
            onClick={changeToNextMonth}
            role="button"
            aria-label={formatMessage({ id: "calendar.nextMonth" })}
            tabIndex={0}
            onKeyUp={onNextPress}
            data-testid="opening-hours-calendar-next-month-button"
          >
            <IconArrowRight />
          </span>
        }
        prevLabel={
          <span
            className="flex items-center justify-center icon-wrapper-large svg-icon stroke-current-color fill-transparent pointer hover-pink ph3-l outline-0"
            onClick={changeToPreviousMonth}
            role="button"
            aria-label={formatMessage({ id: "calendar.prevMonth" })}
            tabIndex={0}
            onKeyUp={onPreviousPress}
            data-testid="opening-hours-calendar-previous-month-button"
          >
            <IconArrowLeft />
          </span>
        }
        nextAriaLabel={formatMessage({ id: "calendar.nextMonth" })}
        prevAriaLabel={formatMessage({ id: "calendar.prevMonth" })}
        minDate={minDate.toJSDate()}
        maxDate={maxDate.toJSDate()}
        value={selectedDate.toJSDate()}
        onClickDay={onClickDay}
      />
      <div className="mt2 ph3-ns">
        {legend && (
          <ul
            className="list mv3 pa0"
            data-testid={TEST_ID_OPENING_HOURS_CALENDAR_LEGEND}
          >
            {legend.ridesOpen && (
              <li
                className={`${styles.paragraph3} dib ph3 pv2 black bg-bright-turquoise tc lh-solid br-pill mr3 mb3`}
              >
                <FormattedMessage id="openingHours.ridesAreOpen" />
              </li>
            )}
            {legend.parkClosed && (
              <li
                className={`${styles.paragraph3} dib ph3 pv2 black bg-light-gray tc lh-solid br-pill mr3 mb3`}
              >
                <FormattedMessage id="openingHours.parkIsClosed" />
              </li>
            )}
            {legend.specialEvents && (
              <li className="flex items-center">
                <span
                  className="bg-pink br-100 dib flex-shrink-0"
                  aria-hidden="true"
                  style={{ width: "0.5rem", height: "0.5rem" }}
                ></span>
                <span className={`${styles.paragraph3} ml2`}>
                  <FormattedMessage id="openingHours.specialEvents" />
                </span>
              </li>
            )}
          </ul>
        )}
        {!selectedDate.hasSame(today, "day") && (
          <button
            className={`${styles.primaryButton} mt3`}
            onClick={handleTodayClick}
            data-testid="opening-hours-calendar-today-button"
          >
            <FormattedMessage id="openingHours.backToToday" />
          </button>
        )}
      </div>
    </div>
  );
};

export default OpeningHoursCalendar;

import classNames from "classnames";
import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { IContentModuleContactFields } from "../../types/generated/contentful";
import Picture from "../Picture";

const Contact: React.FC<IContentModuleContactFields> = ({
  title,
  jobTitle,
  phone,
  image,
  email,
}) => {
  const { isNasinneula, isSarkanniemi, styles } = useContext(ThemeContext);
  return (
    <article className="w-60 w-100-ns flex flex-wrap">
      <div className="w-100 w-30-ns">
        <div className="w-100">
          <div className="aspect-ratio aspect-ratio--1x1 aspect-ratio--3x4-ns">
            <Picture
              title={image.fields.title}
              description={image.fields.description}
              url={image.fields.file.url}
              cssClass="aspect-ratio--object ofi-cover ofi--top"
            />
          </div>
        </div>
      </div>
      <div className="w-100 w-70-ns flex items-center hyphens-auto">
        <div className="pa3">
          <h3
            className={classNames("mt0", "mb3", styles.heading2)}
            data-testid="contact-title"
          >
            {title}
          </h3>
          <ul className={`${styles.paragraph3} list ma0 pa0`}>
            {jobTitle && (
              <li
                className={classNames({
                  mb2: isSarkanniemi,
                  mb3: isNasinneula,
                  "tracked-10": isNasinneula,
                })}
                data-testid="contact-job-title"
              >
                {jobTitle}
              </li>
            )}
            {phone && (
              <li
                className={classNames({
                  mb2: isSarkanniemi,
                  mb3: isNasinneula,
                  "tracked-10": isNasinneula,
                })}
              >
                <a
                  className="color-inherit no-underline hover-pink"
                  href={`tel:${phone}`}
                  data-testid="contact-phone"
                >
                  {phone}
                </a>
              </li>
            )}
            {email && (
              <li
                className={classNames({
                  "tracked-10": isNasinneula,
                })}
              >
                <a
                  className="color-inherit no-underline hover-pink"
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="contact-email"
                >
                  {email}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </article>
  );
};

export default Contact;

import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import BackgroundWavesHorizontal from "../../../public/svg/background-waves-horizontal.svg";
import BackgroundWavesMobile from "../../../public/svg/background-waves-mobile.svg";
import { CalendarContext } from "../../contexts/CalendarContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import { CalendarRowWithDateTime } from "../../types/calendar";
import { IContentModuleOpeningHoursFields } from "../../types/generated/contentful";
import aspectRatioPadding from "../../utils/aspect-ratio-padding";
import OpeningHoursCalendar from "../OpeningHoursCalendar";
import OpeningHoursList from "../OpeningHoursList";
import Picture from "../Picture";

import Button from "./Button";
import css from "./OpeningHours.module.scss";

type OpeningHoursProps = IContentModuleOpeningHoursFields & {
  dateToUseAsToday?: string; // for test purposes
};

const OpeningHours: React.FC<OpeningHoursProps> = ({
  showCalendar,
  image,
  button,
  dateToUseAsToday,
}) => {
  const backgroundColor = showCalendar ? "yellow" : "white";
  const today = dateToUseAsToday
    ? DateTime.fromISO(dateToUseAsToday)
    : DateTime.local().startOf("day");
  const { calendarRows, hasFailed, isLoading } = useContext(CalendarContext);
  const { styles } = useContext(ThemeContext);
  const [selectedDate, setSelectedDate] = useState(today);
  const [openingHourRows, setOpeningHourRows] = useState<
    CalendarRowWithDateTime[]
  >([]);

  useEffect(() => {
    if (!isLoading && !hasFailed) {
      setOpeningHourRows(
        calendarRows.map(row => ({
          ...row,
          Pvm: DateTime.fromISO(row.Pvm),
        })),
      );
    }
  }, [calendarRows, hasFailed, isLoading]);

  const handleDaySelect = useCallback((date: DateTime): void => {
    setSelectedDate(date);
  }, []);

  return (
    <section className={classNames(`bg-${backgroundColor}`, "mb3")}>
      <div
        className="relative flex-ns items-stretch justify-center"
        style={{ minHeight: "300px" }}
      >
        <div
          className={`w-100 w-50-ns relative ${backgroundColor} ${
            image && "vh-50 h-auto-ns"
          }`}
          data-testid="opening-hours-image-and-calendar-wrapper"
        >
          {!showCalendar && image && (
            <Picture
              title={image.fields.title}
              description={image.fields.description}
              url={image.fields.file.url}
              cssClass="absolute top-0 left-0 w-100 h-100 ofi-cover"
            />
          )}
          {showCalendar && openingHourRows.length > 0 ? (
            <div className="bg-white black flex items-center pt2 pb5 pv3-ns pv3-l">
              <section className="relative z-5 ph3 pr4-ns pl5-l pr6-l">
                <OpeningHoursCalendar
                  selectedDate={selectedDate}
                  today={today}
                  calendarRows={openingHourRows}
                  handleDaySelect={handleDaySelect}
                />
              </section>
            </div>
          ) : null}
          <div
            className="absolute bottom-0 left-0 w-100 dn-ns"
            style={{ paddingTop: aspectRatioPadding(320, 30) }}
            aria-hidden="true"
          >
            <div className={css.overlay}>
              <BackgroundWavesMobile />
            </div>
          </div>
          <div
            className="absolute top-0 right-0 w-100 h-100 translate-right-50 dn db-ns"
            style={{}}
            aria-hidden="true"
          >
            <div className={classNames(css.overlay, css.overlayDesktop)}>
              <BackgroundWavesHorizontal />
            </div>
          </div>
        </div>
        <div className="relative w-100 w-50-ns flex flex-column justify-center ph3 pv4 pa5-ns ph6-l pv5-l">
          {isLoading ? (
            <div className={styles.paragraph3}>
              <FormattedMessage id="openingHours.fetchingOpeningHours" />
            </div>
          ) : hasFailed || openingHourRows.length === 0 ? (
            <div className={styles.paragraph3}>
              <FormattedMessage id="openingHours.fetchingOpeningHoursFailed" />
            </div>
          ) : (
            <OpeningHoursList
              today={today}
              calendarRow={openingHourRows.find(openingHour =>
                openingHour.Pvm.equals(selectedDate),
              )}
            />
          )}
          {button && (
            <div className="mt4 mt5-ns">
              <Button {...button.fields} primary />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default OpeningHours;

import classNames from "classnames";
import React from "react";

import { IContentModuleAnchorNavigationFields } from "../../types/generated/contentful";

export const AnchorNavigation: React.FC<
  IContentModuleAnchorNavigationFields
> = ({ links }) => {
  const validLinks = links.filter(
    ({ fields }) => fields.anchorLinkId && fields.heading,
  );
  if (validLinks.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-column items-center mv4 flex-row-ns justify-between-ns ma4-ns">
      {validLinks.map((link, index) => {
        const { anchorLinkId, heading } = link.fields;
        const isLast = index === validLinks.length - 1;
        return (
          <a
            key={`${anchorLinkId}-${index}`}
            href={`#${anchorLinkId}`}
            className={classNames(
              "f5-l",
              "f6",
              "ttu",
              "no-underline",
              "black",
              "tracked-10",
              "mb0-ns",
              {
                mb4: !isLast,
              },
            )}
            data-testid="anchor-navigation-link"
          >
            {heading}
          </a>
        );
      })}
    </div>
  );
};

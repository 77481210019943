import classNames from "classnames";
import React from "react";
import { useIntl } from "react-intl";

interface EcommerceProductImageProps {
  className?: string;
  imageSrc?: string | null;
  productName: string;
}

const EcommerceProductImage: React.FC<EcommerceProductImageProps> = ({
  className,
  imageSrc,
  productName,
}) => {
  const { formatMessage } = useIntl();

  return imageSrc ? (
    <picture>
      <img
        src={imageSrc}
        className={classNames("aspect-ratio--object", "ofi-cover", className)}
        alt={formatMessage(
          {
            id: "product.imageAltText",
          },
          {
            name: productName,
          },
        )}
        data-testid="ecommerce-product-image"
      />
    </picture>
  ) : (
    <picture>
      <img
        src="/images/product-picture-missing.png"
        className={classNames("aspect-ratio--object", "ofi-contain", className)}
        alt={formatMessage({
          id: "product.imageAltTextMissingImage",
        })}
        data-testid="ecommerce-product-image"
      />
    </picture>
  );
};

export default EcommerceProductImage;

import React from "react";

import PictureImage from "./PictureImage";
import PictureSvg from "./PictureSvg";

export interface PictureProps {
  title: string;
  description: string;
  url: string;
  cssClass?: string;
}

const isSvg = (url: string): boolean => {
  return url.endsWith(".svg");
};

const Picture: React.FC<PictureProps> = ({
  title,
  description,
  url,
  cssClass,
}) =>
  isSvg(url) ? (
    <PictureSvg
      title={title || ""}
      description={description || ""}
      url={url}
      cssClass={cssClass}
    />
  ) : (
    <PictureImage
      title={title || ""}
      description={description || ""}
      url={url}
      cssClass={cssClass}
    />
  );

export default Picture;

import classNames from "classnames";
import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import replaceHTMLEntitiesFromText from "../../lib/replace-html-entities";
import { IContentModuleFeatureFields } from "../../types/generated/contentful";
import Picture from "../Picture";

import Button from "./Button";
import Link from "./Link";
import Tag from "./Tag";
import TagContainer from "./TagContainer";

interface FeatureProps extends IContentModuleFeatureFields {
  stacked: boolean;
}

const Feature: React.FC<FeatureProps> = ({
  anchorLinkId,
  image,
  imageAspectRatio,
  heading,
  content,
  link,
  stacked,
  tags,
}) => {
  const { styles } = useContext(ThemeContext);
  return (
    <article
      className={classNames("center mw6 mw-100-l", {
        "flex-l flex-row-l items-center": stacked,
      })}
      id={anchorLinkId}
      data-testid="feature"
    >
      <div className={classNames({ "w-25-l flex-shrink-0": stacked })}>
        <div className={`aspect-ratio aspect-ratio--${imageAspectRatio}`}>
          {link ? (
            <Link {...link.fields}>
              {image.fields && (
                <Picture
                  title={image.fields.title}
                  description={image.fields.description}
                  url={image.fields.file?.url}
                  cssClass="aspect-ratio--object ofi-cover"
                />
              )}
            </Link>
          ) : (
            image.fields && (
              <Picture
                title={image.fields.title}
                description={image.fields.description}
                url={image.fields.file?.url}
                cssClass="aspect-ratio--object ofi-cover"
              />
            )
          )}
        </div>
      </div>
      <div className={classNames("pv3", { "ph4-l w-60-l": stacked })}>
        <h2
          className={classNames(
            styles.heading2,
            "mt0",
            "mb3",
            "hyphens-manual",
          )}
          data-testid="feature-heading"
        >
          {replaceHTMLEntitiesFromText(heading)}
        </h2>
        <div className={`${styles.paragraph3} nested-contained-copy-separator`}>
          <p>{content}</p>
          {tags && tags.length > 0 && (
            <TagContainer>
              {tags.map(tag => (
                <Tag key={tag.sys.id} {...tag.fields} />
              ))}
            </TagContainer>
          )}
        </div>
      </div>
      {link && (
        <div
          className={classNames("mt3", {
            "w-20-l flex-l justify-end": stacked,
          })}
        >
          <Button title="" link={link} primary />
        </div>
      )}
    </article>
  );
};

export default Feature;

import React from "react";

import { heading2 } from "../utils/styles";

interface FlexSectionProps {
  children?: React.ReactNode;
  title: string;
  split?: "half" | "40-60";
}

const classes: { [key: string]: { left: string; right: string } } = {
  half: { left: "w-50-ns", right: "w-50-ns" },
  "40-60": { left: "w-40-ns", right: "w-60-ns" },
};

const FlexSection: React.FC<FlexSectionProps> = ({
  title,
  split = "half",
  children,
}) => (
  <section className="flex flex-wrap items-start pv5 mt0 bb b--light-pink hide-last-border">
    <h1 className={`${heading2()} mt0 ${classes[split].left} w-100`}>
      {title}
    </h1>
    <div className={`${classes[split].right} w-100`}>{children}</div>
  </section>
);

export default FlexSection;

import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface UseRouteChangeIndicator {
  isChangingRoute: boolean;
}

const useRouteChangeIndicator = (
  targetRoute?: string,
): UseRouteChangeIndicator => {
  const [isChangingRoute, setIsChangingRoute] = useState(false);
  const { events } = useRouter();

  const handleRouteChangeStart = (url: string): void => {
    if (!targetRoute || url === targetRoute) {
      setIsChangingRoute(true);
    }
  };

  const handleRouteChangeError = (_: Error, url: string): void => {
    if (!targetRoute || url === targetRoute) {
      setIsChangingRoute(false);
    }
  };

  const handleRouteChangeComplete = (url: string): void => {
    if (!targetRoute || url === targetRoute) {
      setIsChangingRoute(false);
    }
  };

  useEffect(() => {
    events.on("routeChangeStart", handleRouteChangeStart);
    events.on("routeChangeComplete", handleRouteChangeComplete);
    events.on("routeChangeError", handleRouteChangeError);

    return (): void => {
      events.off("routeChangeStart", handleRouteChangeStart);
      events.off("routeChangeComplete", handleRouteChangeComplete);
      events.off("routeChangeError", handleRouteChangeError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isChangingRoute,
  };
};

export default useRouteChangeIndicator;

import { CookieCategory } from "../contexts/CookieConsentContext";
import {
  IContentModuleEmbeddedContentFields,
  IContentModuleVideoFields,
} from "../types/generated/contentful";

export function parseRequiredCookiePermissions(
  permissions:
    | IContentModuleEmbeddedContentFields["cookiePermissions"]
    | IContentModuleVideoFields["cookiePermissions"],
): CookieCategory[] {
  return permissions ? permissions.map(({ fields }) => fields.value) : [];
}

export function getMissingCookiePermissions(
  requiredPermissions: CookieCategory[],
  givenPermissions: CookieCategory[],
): string[] {
  return requiredPermissions.filter(
    required => !givenPermissions.includes(required),
  );
}

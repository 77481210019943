import classNames from "classnames";
import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import sanitizeHtml from "sanitize-html";

import BackgroundWavesSmall from "../../../public/svg/background-waves-small.svg";
import { ContentfulPageContext } from "../../contexts/ContentfulPageContext";
import useRouteChangeIndicator from "../../hooks/useRouteChangeIndicator";
import { CollectionProduct } from "../../types/shopify";
import aspectRatioPadding from "../../utils/aspect-ratio-padding";
import { heading3, paragraph3, primaryButton } from "../../utils/styles";
import LocaleProductLink from "../LocaleProductLink";

import EcommerceProductImage from "./EcommerceProductImage";
import css from "./EcommerceProductListItem.module.scss";
import PriceList from "./PriceList";

export const TEST_ID_SHOW_MORE_BUTTON =
  "ecommerce-product-list-item-show-more-button";

interface EcommerceProductListItemProps {
  product: CollectionProduct;
}

const MAX_DESCRIPTION_LENGTH = 90;
const TERMINATOR = "\u2026";

function getDescription(rawDescription: string) {
  const sanitizedDescription = sanitizeHtml(rawDescription, {
    allowedTags: [],
  });

  if (sanitizedDescription.length <= MAX_DESCRIPTION_LENGTH) {
    return sanitizedDescription;
  }

  const words = sanitizedDescription.split(" ");

  const description = words.reduce((sentence, word) => {
    if (sentence.length <= MAX_DESCRIPTION_LENGTH) {
      return sentence.concat(` ${word}`);
    } else if (!sentence.endsWith(TERMINATOR)) {
      const endsWithSpecialCharacter =
        sentence[sentence.length - 1].match(/[^a-zA-Zäöå€%0-9]/);
      if (endsWithSpecialCharacter !== null) {
        return sentence.replace(/.$/, TERMINATOR);
      }
      return sentence.concat(TERMINATOR);
    }
    return sentence;
  }, "");

  return description;
}

const EcommerceProductListItem: React.FC<EcommerceProductListItemProps> = ({
  product,
}) => {
  const {
    compareAtPriceRange,
    description,
    images,
    handle,
    priceRange,
    title,
  } = product;
  const imageSrc = images.edges.length ? images.edges?.[0].node.url : undefined;
  const { locale } = useIntl();
  const { isChangingRoute } = useRouteChangeIndicator(
    `/${locale}/product/${handle}`,
  );
  const { backgroundClassName, backgroundColor } = useContext(
    ContentfulPageContext,
  );

  return (
    <article
      className="bg-white h-100"
      data-testid="ecommerce-product-list-item"
    >
      <LocaleProductLink product={product}>
        <a className="flex flex-column h-100 no-underline black hover-black">
          <div className="aspect-ratio aspect-ratio--1x1">
            <EcommerceProductImage imageSrc={imageSrc} productName={title} />
          </div>
          <div className="pa3 flex-grow-1 flex flex-column justify-between">
            <div>
              <h3
                className={`${heading3} ma0`}
                data-testid="ecommerce-product-list-item-title"
              >
                {title}
              </h3>
              <div
                className={`${paragraph3} nested-contained-copy-separator mt3`}
                data-testid="ecommerce-product-list-item-description"
              >
                <p>{getDescription(description)}</p>
              </div>
            </div>
            <div className="mt4">
              <PriceList
                priceRange={priceRange}
                compareAtPriceRange={compareAtPriceRange}
              />
              <div className="mt5 tc">
                <span
                  className={primaryButton}
                  data-testid={TEST_ID_SHOW_MORE_BUTTON}
                >
                  <FormattedMessage
                    id={
                      isChangingRoute
                        ? "product.loading"
                        : "product.readMoreAndBuy"
                    }
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              "relative fill-current-color mt4",
              backgroundClassName,
            )}
            style={{
              paddingTop: aspectRatioPadding(335, 33),
              color: backgroundColor,
            }}
            aria-hidden="true"
          >
            <div className={css.overlay}>
              <BackgroundWavesSmall />
            </div>
          </div>
        </a>
      </LocaleProductLink>
    </article>
  );
};

export default EcommerceProductListItem;

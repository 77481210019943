import classNames from "classnames";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

import { CookieConsentContext } from "../contexts/CookieConsentContext";
import { ThemeContext } from "../contexts/ThemeContext";

interface CookiePermissionsMissingProps {
  missingPermissions: string[];
}

export const TEST_ID_COOKIE_PERMISSIONS_MISSING = "cookie-permissions-missing";
export const TEST_ID_COOKIE_PERMISSIONS_MISSING_PERMISSION =
  "cookie-permissions-missing-permission";

const CookiePermissionsMissing: React.FC<CookiePermissionsMissingProps> = ({
  missingPermissions,
}) => {
  const { styles } = useContext(ThemeContext);
  const { updateSettings } = useContext(CookieConsentContext);
  return (
    <div
      className="flex flex-column items-center mv3 pa4 ba bw1 b--light-gray"
      data-testid={TEST_ID_COOKIE_PERMISSIONS_MISSING}
    >
      <p className="mb1 mt0">
        <FormattedMessage id="cookieConsent.noPermissions" />
      </p>
      <ul>
        {missingPermissions.map(permission => (
          <li
            key={permission}
            data-testid={TEST_ID_COOKIE_PERMISSIONS_MISSING_PERMISSION}
          >
            <FormattedMessage id={`cookieConsent.${permission}`} />
          </li>
        ))}
      </ul>
      <button
        className={classNames(styles.primaryButton, "mt3", "mb0")}
        onClick={updateSettings}
      >
        <FormattedMessage id="cookieConsent.updateSettings" />
      </button>
    </div>
  );
};

export default CookiePermissionsMissing;

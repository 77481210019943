import Link from "next/link";
import React from "react";
import { useIntl } from "react-intl";

import { CollectionProduct, Product } from "../types/shopify";

interface LocaleProductLink {
  children?: React.ReactNode;
  product?: Product | CollectionProduct;
  handle?: string;
}

const LocaleProductLink: React.FC<LocaleProductLink> = ({
  product,
  handle,
  children,
}) => {
  const { locale, formatMessage } = useIntl();
  const localeSlug = formatMessage({ id: "appPath.product" });

  return (
    <Link
      href={`/${locale}/${localeSlug}/${handle || product?.handle}`}
      legacyBehavior
    >
      {children}
    </Link>
  );
};

export default LocaleProductLink;

import * as Sentry from "@sentry/nextjs";
import getVideoId from "get-video-id";
import React, { useContext, useMemo } from "react";

import { CookieConsentContext } from "../../contexts/CookieConsentContext";
import { IContentModuleVideoFields } from "../../types/generated/contentful";
import {
  getMissingCookiePermissions,
  parseRequiredCookiePermissions,
} from "../../utils/cookie-consent";
import CookiePermissionsMissing from "../CookiePermissionsMissing";

export const TEST_ID_VIDEO_CONTAINER = "video-container";
export const TEST_ID_VIDEO_EMBED = "video-embed";

interface Services {
  [key: string]: string;
}

const services: Services = {
  youtube: "https://www.youtube.com/embed/",
  vimeo: "https://player.vimeo.com/video/",
};

const getEmbedUrl = (url: string): string | null => {
  const { service, id } = getVideoId(url);

  if (!service || !id) {
    return null;
  }

  switch (service) {
    case "vimeo":
    case "youtube":
      return `${services[service]}${id}`;
    default:
      return null;
  }
};

const Video: React.FC<IContentModuleVideoFields> = ({
  cookiePermissions,
  videoTitle,
  url,
}) => {
  const { givenPermissions } = useContext(CookieConsentContext);
  const requiredPermissions = useMemo(() => {
    return parseRequiredCookiePermissions(cookiePermissions);
  }, [cookiePermissions]);
  const missingPermissions = useMemo(() => {
    return getMissingCookiePermissions(requiredPermissions, givenPermissions);
  }, [givenPermissions, requiredPermissions]);
  const embedURL = getEmbedUrl(url);

  if (!embedURL) {
    Sentry.captureMessage(`Invalid URL in Video component: ${url}`);
    return null;
  }

  return (
    <div
      className="mw8 center ph3 mv4 mv5-ns"
      data-testid={TEST_ID_VIDEO_CONTAINER}
    >
      {missingPermissions.length === 0 ? (
        <div className="aspect-ratio aspect-ratio--16x9">
          <iframe
            title={videoTitle}
            src={embedURL}
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
            className="aspect-ratio--object"
            data-testid={TEST_ID_VIDEO_EMBED}
          ></iframe>
        </div>
      ) : (
        <CookiePermissionsMissing missingPermissions={missingPermissions} />
      )}
    </div>
  );
};

export default Video;

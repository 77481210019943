import classNames from "classnames";
import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { IContentModuleButtonFields } from "../../types/generated/contentful";

import Link from "./Link";

export interface ButtonProps extends IContentModuleButtonFields {
  cssClass?: string;
  primary?: boolean;
}

const Button: React.FC<ButtonProps> = ({ link, cssClass, primary }) => {
  const { internalLink, externalLink, linkTitle, openInNewWindow } =
    link.fields;
  const { styles } = useContext(ThemeContext);
  return (
    <Link
      title=""
      internalLink={internalLink}
      externalLink={externalLink}
      linkTitle={linkTitle}
      openInNewWindow={openInNewWindow}
      cssClass={classNames(
        "Button",
        {
          [styles.primaryButton]: primary,
          [styles.secondaryButton]: !primary,
        },
        cssClass,
      )}
    >
      {linkTitle || internalLink?.fields.title}
    </Link>
  );
};

export default Button;

interface ImageManipulationProps {
  url: string;
  format?: string;
  quality?: number;
  width: number;
}

const defaultQuality = 80;
const defaultFormat = "jpg";

/**
 * Manipulate Contentful Asset image.
 *
 * @see https://www.contentful.com/developers/docs/references/images-api/#/reference
 */
export const imageManipulation = ({
  url,
  format,
  quality,
  width,
}: ImageManipulationProps): string => {
  format = format ? format : defaultFormat;
  quality = quality ? quality : defaultQuality;

  return `${url}?fm=${format}&q=${quality}&w=${width}`;
};

import classNames from "classnames";
import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { CollectionProduct } from "../../types/shopify";

import css from "./EcommerceProductList.module.scss";
import EcommerceProductListItem from "./EcommerceProductListItem";

export interface EcommerceProductListProps {
  title: string;
  products: CollectionProduct[];
  smallTitleSize?: boolean;
}

const EcommerceProductList: React.FC<EcommerceProductListProps> = ({
  title,
  products,
  smallTitleSize,
}) => {
  const { styles } = useContext(ThemeContext);

  if (!products.length) {
    return <></>;
  }

  return (
    <section className="mv4 mv5-l ph3 center">
      <div className={classNames(css.container, "center")}>
        <div>
          <h2
            className={classNames(
              smallTitleSize ? styles.heading1 : styles.headingHero,
              "mt0 mb3 ph3",
              css.title,
            )}
          >
            {title}
          </h2>
        </div>
        <div
          className={classNames(
            "flex flex-wrap justify-center justify-start-l items-stretch nl2 nr2",
            css.list,
          )}
        >
          {products.map(product => (
            <div
              key={`${title}-${product.id}`}
              className={classNames("ph3 w-100 mb3", css.item)}
            >
              <EcommerceProductListItem product={product} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EcommerceProductList;

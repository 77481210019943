import React from "react";
import { useIntl } from "react-intl";

import { IRestaurant } from "../types/generated/contentful";
import px2rem from "../utils/px2rem";
import { heading2, paragraph3 } from "../utils/styles";

import LocaleLink from "./LocaleLink";
import Picture from "./Picture";

interface RestaurantSearchSingleRestaurantProps {
  restaurant: IRestaurant;
  stacked: boolean;
}

const RestaurantSearchSingleRestaurant: React.FC<
  RestaurantSearchSingleRestaurantProps
> = ({ restaurant, stacked }) => {
  const { formatMessage } = useIntl();

  return (
    <article
      className={`h-100 ${stacked ? "bg-white black" : "bg-black white "}`}
    >
      <LocaleLink
        slug={formatMessage(
          { id: "appPath.restaurant" },
          { slug: restaurant.fields.slug },
        )}
      >
        <a
          className={`no-underline color-inherit flex h-100 ${
            stacked ? "" : "flex-column"
          }`}
          data-testid="restaurant-search-single-restaurant-link"
        >
          <div className={`${stacked ? "w-30 w-20-ns flex-shrink-0" : ""}`}>
            <div className="aspect-ratio aspect-ratio--1x1 h-100">
              <Picture
                title={restaurant.fields.image.fields.title}
                description={restaurant.fields.image.fields.description}
                url={restaurant.fields.image.fields.file.url}
                cssClass="ofi-cover aspect-ratio--object"
              />
            </div>
          </div>
          <div
            className={`flex pa3 h-100 ${
              stacked ? "pa4-ns flex-column flex-row-ns" : ""
            }`}
          >
            <div className="flex flex-column justify-between h-100 pr3">
              <div className="pb3">
                <h3 className={`${heading2()} ma0`}>
                  {restaurant.fields.title}
                </h3>
                <p
                  className={`${paragraph3} mt3 mb0 ${
                    stacked ? "dn db-ns" : ""
                  }`}
                >
                  {restaurant.fields.seoDescription}
                </p>
              </div>
              <ul
                className={`${paragraph3} list ma0 pa0 flex flex-wrap`}
                style={{ marginTop: "auto" }}
              >
                {restaurant.fields.foods &&
                  restaurant.fields.foods.map(food => (
                    <li key={food.fields.name} className="pa1 nowrap">
                      <span
                        className={`ba dib ph2 pv1 ${
                          stacked ? "b--black" : "b--white"
                        }`}
                      >
                        {food.fields.name}
                      </span>
                    </li>
                  ))}
                {restaurant.fields.treats &&
                  restaurant.fields.treats.map(treat => (
                    <li key={treat.fields.name} className="pa1 nowrap">
                      <span
                        className={`ba dib ph2 pv1 ${
                          stacked ? "b--black" : "b--white"
                        }`}
                      >
                        {treat.fields.name}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div
              className={`${stacked ? "mt3 mt0-ns" : "dn db-ns"}`}
              style={{ marginLeft: "auto" }}
            >
              <div className="flex items-center flex-shrink-0 h-100 restaurant-diets">
                <ul
                  className={`list ma0 pa0 ${
                    stacked ? "flex db-ns nb4-ns" : "nb2"
                  }`}
                >
                  {restaurant.fields.diets &&
                    restaurant.fields.diets.map(diet => {
                      const icon = stacked
                        ? diet.fields.iconBlack
                        : diet.fields.iconWhite;

                      return (
                        <li
                          key={diet.fields.name}
                          className={`${stacked ? "mr2 mr0-ns mb4-ns" : "mb2"}`}
                          style={{ width: px2rem(30) }}
                        >
                          <Picture
                            title={icon.fields.title}
                            description={icon.fields.description}
                            url={icon.fields.file.url}
                          />
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </a>
      </LocaleLink>
    </article>
  );
};

export default RestaurantSearchSingleRestaurant;

import classnames from "classnames";
import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { IContentModuleFeatureContainerFields } from "../../types/generated/contentful";
import SwiperWrapper from "../SwiperWrapper";

import Feature from "./Feature";

const FeatureContainer: React.FC<IContentModuleFeatureContainerFields> = ({
  heading,
  features,
  display,
}) => {
  const { styles } = useContext(ThemeContext);
  return (
    <div
      className={`center ph3  ${
        display === "grid-carousel" ? "mt4 mb6" : "mv4 mt5-ns mb6-ns"
      }`}
      data-testid="feature-container"
    >
      <div className="mw6 mw-100-l center">
        <h2 className={`${styles.heading1} mt0 mb4`}>{heading}</h2>
      </div>
      {display === "grid-carousel" ? (
        <SwiperWrapper
          slides={features.map(feature => (
            <Feature key={feature.sys.id} {...feature.fields} stacked={false} />
          ))}
        />
      ) : (
        <div
          className={classnames({ "flex-l nl3-l nr3-l": display === "grid" })}
          data-testid="feature-container-items"
        >
          {features.map(feature => (
            <div
              key={feature.sys.id}
              className={classnames("mb5", {
                "mb4-l": display === "no-grid",
                "w-third-l mb0-l ph3-l": display === "grid",
              })}
              data-testid="feature-container-item"
            >
              <Feature {...feature.fields} stacked={display === "no-grid"} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FeatureContainer;

import PhotoSwipe, { Item } from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

interface PhotoSwipeWrapperProps {
  isOpen: boolean;
  index: number;
  items: Item[];
  onClose: () => void;
}

const PhotoSwipeWrapper: React.FC<PhotoSwipeWrapperProps> = ({
  isOpen,
  index,
  items,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const pswpElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pswpElement.current) {
      const photoSwipe = new PhotoSwipe(
        pswpElement.current,
        PhotoSwipeUI_Default,
        items,
        {
          index,
          closeOnScroll: false,
          history: false,
        },
      );

      if (isOpen) {
        photoSwipe.init();
      } else {
        onClose();
      }
    }

    return (): void => {
      onClose();
    };
  }, [isOpen, items, index, onClose]);

  return (
    <div
      className="pswp"
      role="dialog"
      aria-hidden="true"
      ref={pswpElement}
      data-testid="photoswipe"
    >
      <div className="pswp__bg" />
      <div className="pswp__scroll-wrap">
        <div className="pswp__container">
          <div className="pswp__item" />
          <div className="pswp__item" />
          <div className="pswp__item" />
        </div>
        <div className="pswp__ui pswp__ui--hidden">
          <div className="pswp__top-bar">
            <div className="pswp__counter" />
            <button
              className="pswp__button pswp__button--close"
              title={formatMessage({ id: "photoswipe.close" })}
              data-testid="photoswipe-close"
            />
            <button
              className="pswp__button pswp__button--share"
              title={formatMessage({ id: "photoswipe.share" })}
            />
            <button
              className="pswp__button pswp__button--fs"
              title={formatMessage({ id: "photoswipe.fullscreen" })}
            />
            <button
              className="pswp__button pswp__button--zoom"
              title={formatMessage({ id: "photoswipe.zoom" })}
            />
            <div className="pswp__preloader">
              <div className="pswp__preloader__icn">
                <div className="pswp__preloader__cut">
                  <div className="pswp__preloader__donut" />
                </div>
              </div>
            </div>
          </div>
          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div className="pswp__share-tooltip" />
          </div>
          <button
            className="pswp__button pswp__button--arrow--left"
            title={formatMessage({ id: "photoswipe.previous" })}
          />
          <button
            className="pswp__button pswp__button--arrow--right"
            title={formatMessage({ id: "photoswipe.next" })}
          />
          <div className="pswp__caption">
            <div
              className="pswp__caption__center"
              data-testid="photoswipe-caption"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoSwipeWrapper;

import React from "react";

import { PictureProps } from "./Picture";

export const TEST_ID_PICTURE = "picture";
export const TEST_ID_PICTURE_IMAGE = "picture-image";

const PictureImage: React.FC<PictureProps> = ({
  title,
  description,
  url,
  cssClass,
}) => (
  <picture data-testid={TEST_ID_PICTURE}>
    <source
      media="(min-width: 2560px)"
      type="image/webp"
      srcSet={`${url}?fm=webp&q=80&w=2560`}
    />
    <source
      media="(min-width: 1980px)"
      type="image/webp"
      srcSet={`${url}?fm=webp&q=80&w=1980`}
    />
    <source
      media="(min-width: 1600px)"
      type="image/webp"
      srcSet={`${url}?fm=webp&q=80&w=1600`}
    />
    <source
      media="(min-width: 1366px)"
      type="image/webp"
      srcSet={`${url}?fm=webp&q=80&w=1366`}
    />
    <source
      media="(min-width: 768px)"
      type="image/webp"
      srcSet={`${url}?fm=webp&q=80&w=1280`}
    />
    <source
      media="(min-width: 360px)"
      type="image/webp"
      srcSet={`${url}?fm=webp&q=80&w=768`}
    />
    <source
      media="(min-width: 320px)"
      type="image/webp"
      srcSet={`${url}?fm=webp&q=80&w=480`}
    />
    <source
      media="(min-width: 2560px)"
      type="image/jpeg"
      srcSet={`${url}?fm=jpg&q=80&w=2560`}
    />
    <source
      media="(min-width: 1980px)"
      type="image/jpeg"
      srcSet={`${url}?fm=jpg&q=80&w=1980`}
    />
    <source
      media="(min-width: 1600px)"
      type="image/jpeg"
      srcSet={`${url}?fm=jpg&q=80&w=1600`}
    />
    <source
      media="(min-width: 1366px)"
      type="image/jpeg"
      srcSet={`${url}?fm=jpg&q=80&w=1366`}
    />
    <source
      media="(min-width: 768px)"
      type="image/jpeg"
      srcSet={`${url}?fm=jpg&q=80&w=1280`}
    />
    <source
      media="(min-width: 360px)"
      type="image/jpeg"
      srcSet={`${url}?fm=jpg&q=80&w=768`}
    />
    <source
      media="(min-width: 320px)"
      type="image/jpeg"
      srcSet={`${url}?fm=jpg&q=80&w=480`}
    />
    <img
      className={`picture-image ${cssClass}`}
      src={`${url}?fm=jpg&q=80&w=1366`}
      loading="lazy"
      alt={description}
      title={title}
      data-testid={TEST_ID_PICTURE_IMAGE}
    />
  </picture>
);

export default PictureImage;

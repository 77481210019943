export interface Color {
  id: string;
  name: string;
  value: string;
}

export function getBackgroundColor({
  backgroundColor,
  backgroundColorV2,
}: {
  backgroundColor?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  backgroundColorV2?: Record<string, any>;
}): { backgroundClassName?: string; backgroundColor?: string } {
  if (backgroundColorV2?.value) {
    return {
      backgroundColor: backgroundColorV2?.value,
    };
  }
  return {
    backgroundClassName: backgroundColor,
  };
}

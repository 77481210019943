import React from "react";

import { IContentModuleAttractionCategoryDisplayFields } from "../../types/generated/contentful";
import { heading3 } from "../../utils/styles";
import Picture from "../Picture";

const AttractionCategoryDisplay: React.FC<
  IContentModuleAttractionCategoryDisplayFields
> = ({ attractionCategories }) => {
  return (
    <section className="mv4 mv5-ns">
      <ul className="list ma0 pa0 flex justify-center w-100">
        {attractionCategories.map(({ sys: { id }, fields: { icon, name } }) => (
          <li className="mh4 tc" key={id}>
            {icon && (
              <div>
                <Picture
                  title={icon.fields.title}
                  description={icon.fields.description}
                  url={icon.fields.file.url}
                  cssClass="w3 w4-ns mb3"
                />
              </div>
            )}
            <span
              className={`${heading3} db`}
              data-testid="attraction-category-name"
            >
              {name}
            </span>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default AttractionCategoryDisplay;

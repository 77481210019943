import classNames from "classnames";
import React, { useContext } from "react";

import BackgroundWavesMobile from "../../../public/svg/background-waves-mobile.svg";
import BackgroundWavesUpsideMobile from "../../../public/svg/background-waves-upside-mobile.svg";
import BackgroundWavesUpside from "../../../public/svg/background-waves-upside.svg";
import BackgroundWaves from "../../../public/svg/background-waves.svg";
import { ThemeContext } from "../../contexts/ThemeContext";
import { getBackgroundColor } from "../../lib/contentful/colors";
import { IContentModuleHighlightCollectionFields } from "../../types/generated/contentful";
import aspectRatioPadding from "../../utils/aspect-ratio-padding";

import Highlight from "./Highlight";
import css from "./HighlightCollection.module.scss";

const HighlightCollection: React.FC<
  IContentModuleHighlightCollectionFields
> = ({
  heading,
  headingUnderline,
  highlights,
  backgroundColor: _backgroundColor,
  backgroundColorV2,
}) => {
  const { styles } = useContext(ThemeContext);
  const { backgroundClassName, backgroundColor } = getBackgroundColor({
    backgroundColor: _backgroundColor,
    backgroundColorV2,
  });

  const isEven = (value: number): boolean => {
    return value % 2 === 0;
  };

  return (
    <div className="mv5 mv6-l relative">
      <div className="mw8 center ph3 ">
        <h2
          className={classNames(styles.headingHero, "tc ma0", {
            underline: headingUnderline,
          })}
          data-testid="highlight-collection-heading"
        >
          {heading}
        </h2>
      </div>
      <div className="relative">
        <div
          className={classNames(
            "absolute",
            "w-100",
            "bg-white",
            css.waveTop,
            "dn-l",
            backgroundClassName,
          )}
          style={{
            paddingTop: aspectRatioPadding(320, 30),
            backgroundColor: backgroundColor ? "white" : undefined,
            color: backgroundColor,
          }}
        >
          <BackgroundWavesMobile />
        </div>
        <div
          className={classNames(
            "absolute",
            "w-100",
            "bg-white",
            css.waveTop,
            "dn",
            "db-l",
            backgroundClassName,
          )}
          style={{
            paddingTop: aspectRatioPadding(1440, 51),
            backgroundColor: backgroundColor ? "white" : undefined,
            color: backgroundColor,
          }}
        >
          <BackgroundWaves />
        </div>
        <div
          className={classNames(
            "mt4 mt5-l pb5 pb6-ns overflow-hidden",
            backgroundClassName && `bg-${backgroundClassName}`,
          )}
          style={{ backgroundColor }}
        >
          <div className="mw8 center ph3 relative z-1">
            {highlights?.map((highlight, index) => (
              <Highlight
                title={highlight.fields.title}
                heading={highlight.fields.heading}
                description={highlight.fields.description}
                link={highlight.fields.link}
                image={highlight.fields.image}
                imagePatternColor1={highlight.fields.imagePatternColor1}
                imagePatternColor2={highlight.fields.imagePatternColor2}
                isEven={isEven(index++)}
                key={index}
              />
            ))}
          </div>
        </div>
        <div
          className={classNames(
            "absolute",
            "bottom-0",
            "w-100",
            "bg-white",
            css.waveBottom,
            "dn-l",
            backgroundClassName && `bg-${backgroundClassName}`,
          )}
          style={{
            paddingTop: aspectRatioPadding(320, 30),
            backgroundColor: backgroundColor ? "white" : undefined,
            color: backgroundColor,
          }}
        >
          <BackgroundWavesUpsideMobile />
        </div>
        <div
          className={classNames(
            "absolute",
            "bottom-0",
            "w-100",
            "bg-white",
            css.waveBottom,
            "dn",
            "db-l",
            backgroundClassName,
          )}
          style={{
            paddingTop: aspectRatioPadding(1440, 52),
            backgroundColor: backgroundColor ? "white" : undefined,
            color: backgroundColor,
          }}
        >
          <BackgroundWavesUpside />
        </div>
      </div>
    </div>
  );
};

export default HighlightCollection;

import React, { useRef, useEffect } from "react";
import Swiper, { SwiperOptions } from "swiper";

interface SwiperWrapperProps {
  slides: JSX.Element[];
}

const swiperOptions: SwiperOptions = {
  loop: true,
  slidesPerView: 1,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    1199: {
      slidesPerView: 3,
    },
  },
};

const SwiperWrapper: React.FC<SwiperWrapperProps> = ({ slides }) => {
  const swiperElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (swiperElement.current) {
      new Swiper(swiperElement.current, swiperOptions);
    }
  });

  return (
    <div className="nr3 nl3">
      <div className="swiper-container" ref={swiperElement}>
        <div className="swiper-wrapper">
          {slides.map((slide, i) => (
            <div
              key={i}
              className="swiper-slide ph3"
              data-testid="swiper-slide"
            >
              {slide}
            </div>
          ))}
        </div>
      </div>
      <div className="swiper-pagination mt5 w-100 tc"></div>
    </div>
  );
};

export default SwiperWrapper;

import React from "react";

import { IContentModuleLogoFields } from "../../types/generated/contentful";
import Picture from "../Picture";

interface ImageProps extends IContentModuleLogoFields {
  cssClass?: string;
}

const Logo: React.FC<ImageProps> = ({ image, linkLocation, cssClass }) => {
  return (
    <div data-testid="image">
      <a
        className="db"
        href={linkLocation}
        target="_blank"
        rel="noopener noreferrer nofollow"
        data-testid="image-link"
      >
        <Picture
          title={image.fields.title}
          description={image.fields.description}
          url={image.fields.file.url}
          cssClass={cssClass}
        />
      </a>
    </div>
  );
};

export default Logo;

import classNames from "classnames";
import React, { useCallback, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import whatInput from "what-input";

import { ThemeContext } from "../contexts/ThemeContext";
import {
  IAttractionCategory,
  IAttractionHeightRestriction,
} from "../types/generated/contentful";

import Picture from "./Picture";
import { StorageKey } from "./contentful/RideSearch";

interface RideSearchFiltersProps {
  filterHeightRestrictions: IAttractionHeightRestriction[] | undefined;
  filterCategories: IAttractionCategory[] | undefined;
  height: string;
  categories: string[];
  activeFilters: boolean;
  setHeight: (value: string) => void;
  setCategories: (value: string[]) => void;
  resetFilters: () => void;
}

const RideSearchFilters: React.FC<RideSearchFiltersProps> = ({
  filterHeightRestrictions,
  filterCategories,
  height,
  categories,
  activeFilters,
  setHeight,
  setCategories,
  resetFilters,
}) => {
  const { formatMessage } = useIntl();
  const { styles } = useContext(ThemeContext);

  const updateHeight = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const value = e.currentTarget.value;
      const newHeight = value === height ? "" : value;
      setHeight(newHeight);

      if (sessionStorage) {
        sessionStorage.setItem(StorageKey.HEIGHT, newHeight);
      }
    },
    [height, setHeight],
  );

  const updateCategory = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      const value = e.currentTarget.id;
      const newCategories = categories.includes(value)
        ? categories.filter(category => value !== category)
        : [...categories, value];
      setCategories(newCategories);

      if (sessionStorage) {
        if (newCategories.length) {
          sessionStorage.setItem(
            StorageKey.CATEGORIES,
            newCategories.join(","),
          );
        } else {
          sessionStorage.removeItem(StorageKey.CATEGORIES);
        }
      }
    },
    [categories, setCategories],
  );

  const filterClassNames = classNames(
    styles.paragraph2,
    "nowrap ba bw1 br-pill pv3 ph3 ph4-ns no-underline pointer lh-solid tc db b--black",
  );
  const isTouch = whatInput.ask() === "touch";
  const unselectedClasses = classNames(
    "bg-white black",
    !isTouch && "hover-bg-black hover-white",
  );

  const accessibleCategory: IAttractionCategory["fields"] = {
    name: formatMessage({ id: "Accessible" }),
    title: "Saavutettava",
  };

  return (
    <div className="flex-ns flex-column">
      <div className="flex-ns">
        <div className="ph3 w-50-ns">
          <span className={`${styles.heading3} db mb3`}>
            <FormattedMessage id="rideSearch.chooseYourHeight" />
          </span>
          <ul className="list ma0 pa0 flex flex-wrap na2">
            {filterHeightRestrictions &&
              filterHeightRestrictions.map(heightRestriction => (
                <li
                  key={heightRestriction.fields.name}
                  className="w-50 w-third-l pa2"
                >
                  <label
                    className={classNames(
                      filterClassNames,
                      height === heightRestriction.fields.name
                        ? "white bg-black"
                        : unselectedClasses,
                    )}
                  >
                    <input
                      type="checkbox"
                      name="height-restriction"
                      value={heightRestriction.fields.name}
                      checked={height === heightRestriction.fields.name}
                      className="clip"
                      onChange={updateHeight}
                      data-testid="ride-search-filters-height-restriction"
                    />
                    {heightRestriction.fields.name}
                  </label>
                </li>
              ))}
          </ul>
        </div>
        <div className="ph3 w-50-ns mt4 mt0-ns">
          <span className={`${styles.heading3} db mb3`}>
            <FormattedMessage id="rideSearch.refineSearch" />
          </span>
          <ul className="list ma0 pa0 flex flex-wrap na2">
            {filterCategories &&
              [
                ...filterCategories.map(({ fields }) => fields),
                accessibleCategory,
              ].map(({ icon, name }) => (
                <li
                  key={name}
                  className="pa2 w-50 w-third-l"
                  data-testid="ride-search-filters-category-list-item"
                >
                  <button
                    className={classNames(
                      filterClassNames,
                      "w-100",
                      categories.includes(name)
                        ? "white bg-black"
                        : unselectedClasses,
                    )}
                    onClick={updateCategory}
                    id={name}
                    data-testid="ride-search-filters-category"
                  >
                    {icon && (
                      <Picture
                        title={name}
                        description=""
                        url={icon.fields.file.url}
                        cssClass="center"
                      />
                    )}
                    <span
                      className={`${styles.paragraph2} db`}
                      data-testid={`ride-search-filters-category-${name}`}
                    >
                      {name}
                    </span>
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
      {activeFilters && (
        <div className="dn db-l ph3 tc w-100 mt3">
          <button
            className={`${styles.textButton} hover-black`}
            onClick={resetFilters}
          >
            <FormattedMessage id="rideSearch.clearAllFilters" />
          </button>
        </div>
      )}
    </div>
  );
};

export default RideSearchFilters;

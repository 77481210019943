import React from "react";
import { FormattedMessage } from "react-intl";

import { CollectionProduct } from "../../types/shopify";
import { heading3 } from "../../utils/styles";
import Money from "../Money";

export const TEST_ID_PRICE_LIST = "price-list";
export const TEST_ID_PRICE_LIST_PRICE = "price-list-price";

interface PriceListProps {
  compareAtPriceRange: CollectionProduct["compareAtPriceRange"];
  priceRange: CollectionProduct["priceRange"];
}

const PriceList: React.FC<PriceListProps> = ({
  compareAtPriceRange,
  priceRange,
}) => {
  const { minVariantPrice, maxVariantPrice } = priceRange;
  const minPrice = Number(minVariantPrice.amount);
  const maxPrice = Number(maxVariantPrice.amount);
  const singlePrice = minPrice === maxPrice;
  const compareAtPrice = Number(compareAtPriceRange.maxVariantPrice.amount);
  const showCompareAtPrice = compareAtPrice > minPrice;
  return (
    <ul
      className={`${heading3} list ma0 pa0 nb3`}
      data-testid={TEST_ID_PRICE_LIST}
    >
      <li
        className="mb3 flex justify-between items-center"
        data-testid={TEST_ID_PRICE_LIST_PRICE}
      >
        {singlePrice ? (
          <Money value={minPrice} />
        ) : (
          <span>
            <FormattedMessage
              id="prices.startingFrom"
              values={{ value: <Money value={minPrice} /> }}
            />
          </span>
        )}
        {showCompareAtPrice && (
          <Money className="gray strike f6" value={compareAtPrice} />
        )}
      </li>
    </ul>
  );
};

export default PriceList;

import React from "react";

import css from "./TagContainer.module.scss";

export const TEST_ID_TAG_CONTAINER = "tag-container";

interface TagContainerProps {
  children?: React.ReactNode;
}

const TagContainer: React.FC<TagContainerProps> = ({ children }) => {
  return (
    <div className={css.container} data-testid={TEST_ID_TAG_CONTAINER}>
      {children}
    </div>
  );
};

export default TagContainer;

import React, { useState, useCallback } from "react";

import IconChevronDown from "../../public/svg/icon-chevron-down.svg";
import IconChevronUp from "../../public/svg/icon-chevron-up.svg";
import { paragraph1 } from "../utils/styles";

export const testIdContent = "content-toggle-content";

interface ContentToggleProps {
  children?: React.ReactNode;
  title: string;
  titleClassName?: string;
  displayChildrenOnly?: boolean;
}

const ContentToggle: React.FC<ContentToggleProps> = ({
  children,
  title,
  titleClassName,
  displayChildrenOnly,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  return displayChildrenOnly ? (
    <>{children}</>
  ) : (
    <section>
      <button
        onClick={handleVisibilityChange}
        className="bw0 ph0 bg-transparent pointer hover-light-pink flex items-center justify-between w-100"
        data-testid="content-toggle-button"
      >
        <span
          className={titleClassName ? `${titleClassName}` : `${paragraph1}`}
          data-testid="content-toggle-title"
        >
          {title}
        </span>
        {isVisible ? (
          <div
            className="svg-icon-large stroke-current-color fill-transparent"
            data-testid="content-toggle-icon-hide"
          >
            <IconChevronUp />
          </div>
        ) : (
          <div
            className="svg-icon-large stroke-current-color fill-transparent"
            data-testid="content-toggle-icon-show"
          >
            <IconChevronDown />
          </div>
        )}
      </button>
      <div className={isVisible ? "db" : "dn"} data-testid={testIdContent}>
        {children}
      </div>
    </section>
  );
};

export default ContentToggle;

import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebouncedCallback } from "use-debounce";

import IconArrowLeft from "../../../public/svg/icon-arrow-left.svg";
import {
  IContentModuleRestaurantSearchFields,
  IRestaurant,
} from "../../types/generated/contentful";
import { heading2, paragraph3, textButton } from "../../utils/styles";
import Picture from "../Picture";
import RestaurantSearchFilters from "../RestaurantSearchFilters";
import RestaurantSearchSingleRestaurant from "../RestaurantSearchSingleRestaurant";
import RestaurantSearchViewToggle from "../RestaurantSearchViewToggle";

export enum View {
  LIST = "list",
  SEARCH = "search",
}

export enum StorageKey {
  FOODS = "restaurant-search-foods",
  TREATS = "restaurant-search-treats",
}

const RestaurantSearch: React.FC<IContentModuleRestaurantSearchFields> = ({
  restaurants,
  filterFoods,
  filterTreats,
  filterDiets,
}) => {
  const { formatMessage } = useIntl();
  const mobileFilters = useRef(null);
  const [bodyScrollTarget, setBodyScrollTarget] = useState<Element | null>(
    null,
  );
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [view, setView] = useState<View>(View.SEARCH);
  const [foods, setFoods] = useState<string[]>([]);
  const [treats, setTreats] = useState<string[]>([]);
  const activeFilters = !!foods.length || !!treats.length;

  const resetFilters = useCallback((): void => {
    setFoods([]);
    setTreats([]);

    if (sessionStorage) {
      sessionStorage.removeItem(StorageKey.FOODS);
      sessionStorage.removeItem(StorageKey.TREATS);
    }
  }, []);

  const closeFilters = useCallback((): void => {
    setFiltersOpen(false);

    if (bodyScrollTarget) {
      enableBodyScroll(bodyScrollTarget);
    }
  }, [bodyScrollTarget]);

  const openFilters = useCallback((): void => {
    setFiltersOpen(true);

    if (bodyScrollTarget) {
      disableBodyScroll(bodyScrollTarget);
    }
  }, [bodyScrollTarget]);

  const toggleFilters = useCallback((): void => {
    filtersOpen ? closeFilters() : openFilters();
  }, [closeFilters, openFilters, filtersOpen]);

  const setViewToSearch = useCallback((): void => {
    setView(View.SEARCH);
  }, []);

  const setViewToList = useCallback((): void => {
    setView(View.LIST);
  }, []);

  const handleWindowResize = useCallback(() => {
    if (filtersOpen && window.innerWidth >= 1200) {
      closeFilters();
    }
  }, [closeFilters, filtersOpen]);

  const windowResize = useDebouncedCallback(handleWindowResize, 250);

  const matchesFoodsFilter = useCallback(
    (restaurant: IRestaurant) => {
      const restaurantFoods = restaurant.fields.foods?.map(
        food => food.fields.name,
      );

      return foods.some(food => restaurantFoods?.includes(food));
    },
    [foods],
  );

  const matchesTreatsFilter = useCallback(
    (restaurant: IRestaurant) => {
      const restaurantTreats = restaurant.fields.treats?.map(
        treat => treat.fields.name,
      );

      return treats.some(treat => restaurantTreats?.includes(treat));
    },
    [treats],
  );

  const restaurantsToDisplay = useMemo(() => {
    return activeFilters
      ? restaurants.filter(restaurant => {
          if (foods.length || treats.length) {
            const matchesFoods = matchesFoodsFilter(restaurant);
            const matchesTreats = matchesTreatsFilter(restaurant);

            if (foods.length && treats.length) {
              if (!matchesFoods && !matchesTreats) {
                return false;
              }
            } else if (foods.length) {
              if (!matchesFoods) {
                return false;
              }
            } else if (treats.length) {
              if (!matchesTreats) {
                return false;
              }
            }
          }

          return true;
        })
      : restaurants;
  }, [
    activeFilters,
    foods,
    matchesFoodsFilter,
    matchesTreatsFilter,
    restaurants,
    treats,
  ]);

  const dietsMarkup = (): JSX.Element => (
    <ul className="list ma0 pa0 flex justify-center w-100">
      {filterDiets &&
        filterDiets.map(diet => (
          <li
            key={diet.fields.name}
            className="flex flex-column flex-row-ns items-center mh3"
          >
            <Picture
              title={diet.fields.iconBlack.fields.title}
              description={diet.fields.iconBlack.fields.description}
              url={diet.fields.iconBlack.fields.file.url}
            />
            <span
              className={`${paragraph3} mt3 mt0-ns ml3-ns db`}
              data-testid="restaurant-search-diet-restriction-name"
            >
              {diet.fields.name}
            </span>
          </li>
        ))}
    </ul>
  );

  useEffect(() => {
    if (sessionStorage) {
      const foods = sessionStorage.getItem(StorageKey.FOODS)?.split(",");
      const treats = sessionStorage.getItem(StorageKey.TREATS)?.split(",");

      if (foods?.length) {
        setFoods(foods);
      }

      if (treats?.length) {
        setTreats(treats);
      }
    }
  }, []);

  useEffect(() => {
    setBodyScrollTarget(mobileFilters.current);

    return (): void => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", windowResize);

    return (): void => {
      window.removeEventListener("resize", windowResize);
    };
  }, [windowResize]);

  return (
    <section>
      <RestaurantSearchViewToggle
        view={view}
        setViewToSearch={setViewToSearch}
        setViewToList={setViewToList}
      />
      {view === "search" ? (
        <>
          <div
            className={`fixed top-0 left-0 w-100 h-100 pv3 bg-white z-max cover momentum-scrolling ${
              filtersOpen ? "db" : "dn"
            } db-l static-l h-auto-l w-auto-l overflow-auto`}
            data-testid="restaurant-search-mobile-filters"
            ref={mobileFilters}
          >
            <div className="flex justify-between items-center mb3 dn-l">
              <div className="">
                <button
                  className="icon-wrapper-large svg-icon stroke-current-color fill-transparent bw0 bg-transparent pointer hover-pink ph3"
                  aria-label={formatMessage({ id: "rideSearch.closeFilters" })}
                  onClick={closeFilters}
                  data-testid="restaurant-search-mobile-filters-close"
                >
                  <IconArrowLeft />
                </button>
              </div>
              {activeFilters && (
                <div className="">
                  <button
                    className={`${textButton} hover-black ph3`}
                    onClick={resetFilters}
                  >
                    <FormattedMessage id="rideSearch.clearAllFilters" />
                  </button>
                </div>
              )}
            </div>
            <RestaurantSearchFilters
              filterFoods={filterFoods?.filter(value => value.fields)}
              filterTreats={filterTreats?.filter(value => value.fields)}
              foods={foods}
              treats={treats}
              activeFilters={activeFilters}
              setFoods={setFoods}
              setTreats={setTreats}
              resetFilters={resetFilters}
            />
          </div>
          <div>
            <div className="tc mb3 flex justify-between ph3 mv4 mt5-l justify-center-l">
              <h2
                className={`${heading2()} ma0`}
                data-testid="restaurant-search-results"
              >
                {activeFilters ? (
                  restaurantsToDisplay.length ? (
                    <FormattedMessage
                      id="rideSearch.foundResults"
                      values={{ value: restaurantsToDisplay.length }}
                    />
                  ) : (
                    <FormattedMessage id="rideSearch.noFoundResults" />
                  )
                ) : (
                  <FormattedMessage id="restaurantSearch.allRestaurants" />
                )}
              </h2>
              <button
                className={`${textButton} hover-black dn-l`}
                onClick={toggleFilters}
                data-testid="restaurant-search-mobile-filters-open"
              >
                <FormattedMessage id="rideSearch.filters" />
              </button>
            </div>
            <div className="mb3 ph3 dn db-ns">{dietsMarkup()}</div>
          </div>
          <div className="ph2 mb4">
            <div className="flex flex-wrap items-stretch justify-center justify-start-ns">
              {restaurantsToDisplay.map(restaurant => (
                <div
                  key={restaurant.fields.slug}
                  className="w-100 mw6 mw-100-ns w-50-ns w-third-l pa2"
                  data-testid={`restaurant-search-${restaurant.fields.slug}`}
                >
                  <RestaurantSearchSingleRestaurant
                    restaurant={restaurant}
                    stacked={false}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div
          className="mv4 pb4 ph3 bg-near-white"
          data-testid="restaurant-search-list-view"
        >
          <div className="w-80-ns center">
            <div className="pv4">{dietsMarkup()}</div>
            {restaurantsToDisplay.map(restaurant => (
              <div
                key={restaurant.fields.slug}
                className="w-100 pa2"
                data-testid={`restaurant-search-${restaurant.fields.slug}`}
              >
                <RestaurantSearchSingleRestaurant
                  restaurant={restaurant}
                  stacked={true}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default RestaurantSearch;

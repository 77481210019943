import classNames from "classnames";
import React from "react";

import { getBackgroundColor } from "../../lib/contentful/colors";
import { ITagFields } from "../../types/generated/contentful";

import css from "./Tag.module.scss";

export const TEST_ID_TAG = "tag";

const Tag: React.FC<ITagFields> = ({
  backgroundColor: _backgroundColor,
  backgroundColorV2,
  text,
  textColor,
}) => {
  const { backgroundClassName, backgroundColor } = getBackgroundColor({
    backgroundColor: _backgroundColor,
    backgroundColorV2,
  });
  return (
    <span
      className={classNames(
        css.container,
        textColor,
        "f7",
        "f6-l",
        backgroundClassName && `bg-${backgroundClassName}`,
      )}
      style={{ backgroundColor }}
      data-testid={TEST_ID_TAG}
    >
      {text}
    </span>
  );
};

export default Tag;

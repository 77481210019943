import React from "react";
import { FormattedMessage } from "react-intl";

import { paragraph1 } from "../utils/styles";

interface RestaurantSearchViewToggle {
  view: "search" | "list";
  setViewToSearch: () => void;
  setViewToList: () => void;
}

const RestaurantSearchViewToggle: React.FC<RestaurantSearchViewToggle> = ({
  view,
  setViewToSearch,
  setViewToList,
}) => (
  <div className="w-50-ns center ph3 mv4 mv5-ns">
    <ul className="list ma0 pa0 w-100 flex justify-center bg-near-white br-pill">
      <li
        className={`relative w-50 br-pill ${
          view === "search" ? "z-1 bg-black white" : "bg-near-white"
        }`}
      >
        <button
          className="bg-transparent color-inherit bw0 pointer w-100 h-100 pv2 pv3-ns ph3"
          onClick={setViewToSearch}
          data-testid="restaurant-search-view-toggle-search"
        >
          <span className={`f7 f6-ns ${paragraph1} db ttu`}>
            <FormattedMessage id="restaurantSearch.exploreRestaurants" />
          </span>
        </button>
      </li>
      <li
        className={`relative w-50 br-pill ${
          view === "list" ? "z-1 bg-black white" : "bg-near-white"
        }`}
      >
        <button
          className="bg-transparent color-inherit bw0 pointer w-100 h-100 pv2 pv3-ns ph3"
          onClick={setViewToList}
          data-testid="restaurant-search-view-toggle-list"
        >
          <span className={`f7 f6-ns ${paragraph1} db ttu`}>
            <FormattedMessage id="restaurantSearch.restaurantsList" />
          </span>
        </button>
      </li>
    </ul>
  </div>
);

export default RestaurantSearchViewToggle;

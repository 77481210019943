import classNames from "classnames";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import whatInput from "what-input";

import {
  IRestaurantFoods,
  IRestaurantTreats,
} from "../types/generated/contentful";
import { heading3, paragraph2, textButton } from "../utils/styles";

import css from "./RestaurantSearchFilters.module.scss";
import { StorageKey } from "./contentful/RestaurantSearch";

interface RestaurantSearchFiltersProps {
  filterFoods: IRestaurantFoods[] | undefined;
  filterTreats: IRestaurantTreats[] | undefined;
  foods: string[];
  treats: string[];
  activeFilters: boolean;
  setFoods: (value: string[]) => void;
  setTreats: (value: string[]) => void;
  resetFilters: () => void;
}

const RestaurantSearchFilters: React.FC<RestaurantSearchFiltersProps> = ({
  filterFoods,
  filterTreats,
  foods,
  treats,
  activeFilters,
  setFoods,
  setTreats,
  resetFilters,
}) => {
  const updateFoods = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const value = e.currentTarget.id;
      const newFoods = foods.includes(value)
        ? foods.filter(food => value !== food)
        : [...foods, value];
      setFoods(newFoods);

      if (sessionStorage) {
        if (newFoods.length) {
          sessionStorage.setItem(StorageKey.FOODS, newFoods.join(","));
        } else {
          sessionStorage.removeItem(StorageKey.FOODS);
        }
      }
    },
    [foods, setFoods],
  );

  const updateTreats = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      const value = e.currentTarget.id;
      const newTreats = treats.includes(value)
        ? treats.filter(treat => value !== treat)
        : [...treats, value];
      setTreats(newTreats);

      if (sessionStorage) {
        if (newTreats.length) {
          sessionStorage.setItem(StorageKey.TREATS, newTreats.join(","));
        } else {
          sessionStorage.removeItem(StorageKey.TREATS);
        }
      }
    },
    [treats, setTreats],
  );

  const isTouch = whatInput.ask() === "touch";
  const filterClasses = classNames(
    "black bg-white",
    !isTouch && "hover-white hover-bg-black",
  );

  return (
    <div className="flex-ns">
      <div className="ph3 w-50-ns w-45-l">
        <span className={`${heading3} db mb3`}>
          <FormattedMessage id="restaurantSearch.foods" />
        </span>
        <ul className="list ma0 pa0 flex flex-wrap">
          {filterFoods &&
            filterFoods.map(filterFood => (
              <li
                key={filterFood.fields.name}
                className={classNames("w-50", "pa2", css.filterItem)}
              >
                <label
                  className={`${paragraph2} nowrap ba bw1 br-pill pv3 ph3 no-underline pointer lh-solid tc db b--black ${
                    foods.includes(filterFood.fields.name)
                      ? "white bg-black"
                      : filterClasses
                  }`}
                >
                  <input
                    type="checkbox"
                    name="food-restriction"
                    value={filterFood.fields.name}
                    checked={foods.includes(filterFood.fields.name)}
                    className="clip"
                    onChange={updateFoods}
                    id={filterFood.fields.name}
                    data-testid="restaurant-search-filters-food-restriction"
                  />
                  {filterFood.fields.name}
                </label>
              </li>
            ))}
        </ul>
      </div>
      <div className="ph3 w-50-ns w-45-l mt4 mt0-ns">
        <span className={`${heading3} db mb3`}>
          <FormattedMessage id="restaurantSearch.treats" />
        </span>
        <ul className="list ma0 pa0 flex flex-wrap">
          {filterTreats &&
            filterTreats.map(filterTreat => (
              <li
                key={filterTreat.fields.name}
                className={classNames("w-50", "pa2", css.filterItem)}
              >
                <label
                  className={`${paragraph2} nowrap ba bw1 br-pill pv3 ph3 ph4-ns no-underline pointer lh-solid tc db b--black ${
                    treats.includes(filterTreat.fields.name)
                      ? "white bg-black"
                      : filterClasses
                  }`}
                >
                  <input
                    type="checkbox"
                    name="food-restriction"
                    value={filterTreat.fields.name}
                    checked={treats.includes(filterTreat.fields.name)}
                    className="clip"
                    onChange={updateTreats}
                    id={filterTreat.fields.name}
                    data-testid="restaurant-search-filters-treat-restriction"
                  />
                  {filterTreat.fields.name}
                </label>
              </li>
            ))}
        </ul>
      </div>
      <div className="dn db-l ph3 tr w-10-l flex-shrink-0">
        {activeFilters && (
          <button
            className={`${textButton} hover-black nowrap`}
            onClick={resetFilters}
            data-testid="restaurant-search-filters-clear-button"
          >
            <FormattedMessage id="rideSearch.clearAllFilters" />
          </button>
        )}
      </div>
    </div>
  );
};

export default RestaurantSearchFilters;

import React, { useCallback, useContext } from "react";
import { FormattedMessage } from "react-intl";

import { ThemeContext } from "../contexts/ThemeContext";

import { View } from "./contentful/RideSearch";

interface RideSearchViewToggle {
  view: View;
  handleViewChange: (view: View) => void;
}

const RideSearchViewToggle: React.FC<RideSearchViewToggle> = ({
  handleViewChange,
  view,
}) => {
  const { styles } = useContext(ThemeContext);

  const setViewToSearch = useCallback(() => {
    handleViewChange(View.SEARCH);
  }, [handleViewChange]);

  const setViewToList = useCallback(() => {
    handleViewChange(View.LIST);
  }, [handleViewChange]);

  return (
    <div className="w-50-ns center ph3 mv4 mv5-ns">
      <ul className="list ma0 pa0 w-100 flex justify-center bg-near-white br-pill">
        <li
          className={`relative w-50 br-pill ${
            view === "search" ? "z-1 bg-black white" : "bg-near-white"
          }`}
          data-testid="ride-search-view-toggle-explore-rides"
        >
          <button
            className="bg-transparent color-inherit bw0 pointer w-100 h-100 pv2 pv3-ns ph3"
            onClick={setViewToSearch}
          >
            <span className={`f7 f6-ns ${styles.paragraph1} db ttu`}>
              <FormattedMessage id="rideSearch.exploreRides" />
            </span>
          </button>
        </li>
        <li
          className={`relative w-50 br-pill ${
            view === "list" ? "z-1 bg-black white" : "bg-near-white"
          }`}
          data-testid="ride-search-view-toggle-list"
        >
          <button
            className="bg-transparent color-inherit bw0 pointer w-100 h-100 pv2 pv3-ns ph3"
            onClick={setViewToList}
          >
            <span className={`f7 f6-ns ${styles.paragraph1} db ttu`}>
              <FormattedMessage id="rideSearch.ridesList" />
            </span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default RideSearchViewToggle;

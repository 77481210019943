import classNames from "classnames";
import React, { useCallback, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import Masonry, { MasonryProps } from "react-masonry-css";

import { ThemeContext } from "../contexts/ThemeContext";

interface MasonryGridProps extends Partial<MasonryProps> {
  children?: null;
  className: string;
  items: React.ReactNode[];
  showMoreCount: number;
  initialVisibleCount: number;
  canFetchMoreItems: boolean;
  fetchMoreItems?: () => void;
}

const MasonryGrid: React.FC<MasonryGridProps> = ({
  breakpointCols,
  canFetchMoreItems,
  className,
  columnClassName,
  fetchMoreItems,
  initialVisibleCount,
  items,
  showMoreCount,
}) => {
  const { styles } = useContext(ThemeContext);
  const [visibleCount, setVisibleCount] = useState(initialVisibleCount);
  const onShowMoreClick = useCallback(() => {
    if (fetchMoreItems && visibleCount === items.length) {
      fetchMoreItems();
    }
    setVisibleCount(currentVisibleCount => currentVisibleCount + showMoreCount);
  }, [showMoreCount, fetchMoreItems, items.length, visibleCount]);

  return (
    <div className={classNames(className)} data-testid="masonry-grid">
      <Masonry
        className="masonry-grid"
        columnClassName={classNames("masonry-grid-column", columnClassName)}
        breakpointCols={
          breakpointCols || {
            768: 1,
            1200: 2,
            default: 4,
          }
        }
      >
        {items.slice(0, visibleCount)}
      </Masonry>
      {(visibleCount !== items.length ||
        (canFetchMoreItems && fetchMoreItems)) && (
        <div className="flex flex-column items-center mt4">
          <button
            className={classNames(styles.primaryButton)}
            onClick={onShowMoreClick}
            data-testid="masonry-grid-show-more-button"
          >
            <FormattedMessage id="socialMediaWall.showMore" />
          </button>
        </div>
      )}
    </div>
  );
};

export default MasonryGrid;

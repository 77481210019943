import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconAccessible from "../../public/svg/icon-accessible.svg";
import IconChildWithAdult from "../../public/svg/icon-child-with-adult.svg";
import IconChild from "../../public/svg/icon-child.svg";
import { ThemeContext } from "../contexts/ThemeContext";
import { buildEntryLink } from "../lib/contentful/build-entry-link";
import {
  IAttraction,
  IAttractionHeightRestriction,
} from "../types/generated/contentful";

import Icon from "./Icon";
import LocaleLink from "./LocaleLink";
import Picture from "./Picture";

function getHeightRestriction(
  restrictions: IAttractionHeightRestriction[] | undefined,
  translations: { maximum: string; minimum: string },
): string | undefined {
  if (!restrictions) {
    return undefined;
  }

  const noHeightRestriction = restrictions.find(
    ({ fields }) =>
      fields.minimumHeight === undefined && fields.maximumHeight === undefined,
  );

  if (noHeightRestriction) {
    return noHeightRestriction.fields.name;
  }

  const minimumHeight = Math.min(
    ...(restrictions
      .map(({ fields }) => fields.minimumHeight)
      .filter(height => height !== undefined) as number[]),
  );
  const maximumHeight = Math.max(
    ...(restrictions
      .map(({ fields }) => fields.maximumHeight)
      .filter(height => height !== undefined) as number[]),
  );

  const noMaximumHeight = restrictions.some(
    ({ fields }) =>
      fields.maximumHeight === undefined &&
      typeof fields.minimumHeight === "number",
  );

  if (noMaximumHeight) {
    return `${translations.minimum} ${minimumHeight} cm`;
  }

  const noMinimumHeight = restrictions.some(
    ({ fields }) =>
      fields.minimumHeight === undefined &&
      typeof fields.maximumHeight === "number",
  );

  if (noMinimumHeight) {
    return `${translations.maximum} ${maximumHeight + 1} cm`;
  }

  return `${minimumHeight} - ${maximumHeight} cm`;
}

interface RideSearchSingleRideProps {
  ride: IAttraction;
}

const RideSearchSingleRide: React.FC<RideSearchSingleRideProps> = ({
  ride,
}) => {
  const { formatMessage } = useIntl();
  const { styles } = useContext(ThemeContext);
  const translations = {
    maximum: formatMessage({ id: "rideSearch.maximum" }),
    minimum: formatMessage({ id: "rideSearch.minimum" }),
  };

  const heightRestriction = getHeightRestriction(
    ride.fields.heightRestriction,
    translations,
  );

  const heightRestrictionWithAdult = getHeightRestriction(
    ride.fields.heightRestrictionWithAdult,
    translations,
  );

  return (
    <article className="bg-black white h-100">
      <LocaleLink slug={buildEntryLink(ride)}>
        <a
          className="no-underline color-inherit db"
          data-testid="ride-search-single-ride-link"
        >
          <div className="aspect-ratio aspect-ratio--1x1">
            <Picture
              title={ride.fields.image.fields.title}
              description={ride.fields.image.fields.description}
              url={ride.fields.image.fields.file.url}
              cssClass="ofi-cover aspect-ratio--object"
            />
          </div>
          <div className="pa3" data-testid="ride-search-single-ride-title">
            <h3 className={`${styles.heading2} ma0`}>{ride.fields.title}</h3>
          </div>
          <div className="ph3 pb3 flex justify-between">
            <ul className="list ma0 pa0 w-50 w-auto-ns">
              {heightRestriction && (
                <li className={`${styles.paragraph2} flex items-center`}>
                  <Icon size={28} icon={<IconChild />} />
                  <span
                    className="ml2 lh-solid"
                    data-testid="ride-search-single-ride-height-restriction"
                  >
                    {heightRestriction}
                  </span>
                </li>
              )}
              {heightRestrictionWithAdult && (
                <li className={`${styles.paragraph2} flex items-center mt2`}>
                  <Icon size={28} icon={<IconChildWithAdult />} />
                  <span
                    className="ml2 lh-solid"
                    data-testid="ride-search-single-ride-height-restriction-with-adult"
                  >
                    {heightRestrictionWithAdult}
                  </span>
                </li>
              )}
              {ride.fields.isAccessible && (
                <li className={`${styles.paragraph2} flex items-center mt2`}>
                  <Icon size={28} icon={<IconAccessible />} />
                  <span
                    className="ml2 lh-solid"
                    data-testid="ride-search-single-ride-accessible"
                  >
                    <FormattedMessage id="rideSearch.accessible" />
                  </span>
                </li>
              )}
            </ul>
            <ul className="list ma0 pa0 w-60 w-auto-ns flex flex-wrap flex-nowrap-ns justify-end na2">
              {ride.fields.attractionCategory?.map(
                ({ fields: { icon, name } }) => (
                  <li className="pa2 tc" key={name}>
                    {icon && (
                      <Picture
                        title={name}
                        description=""
                        url={icon.fields.file.url}
                        cssClass="center"
                      />
                    )}
                    <span
                      className={`${styles.paragraph2} db`}
                      data-testid="ride-search-single-ride-category-name"
                    >
                      {name}
                    </span>
                  </li>
                ),
              )}
            </ul>
          </div>
        </a>
      </LocaleLink>
    </article>
  );
};

export default RideSearchSingleRide;

import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import {
  BLOCKS,
  INLINES,
  Node,
  Text,
  Inline,
} from "@contentful/rich-text-types";
import classNames from "classnames";
import { Asset } from "contentful";
import React, { ReactNode, useContext } from "react";
import { useIntl } from "react-intl";

import { ThemeContext } from "../../contexts/ThemeContext";
import { buildEntryLink } from "../../lib/contentful/build-entry-link";
import {
  IContentModuleButtonFields,
  IContentModuleRichTextFields,
} from "../../types/generated/contentful";
import Picture from "../Picture";

import Button from "./Button";

interface InlineText extends Node {
  nodeType: INLINES;
  content: Array<Text>;
}

interface MimeHandler {
  (asset: Asset): ReactNode;
}

const mimeHandlers = new Map<string, MimeHandler>();

mimeHandlers.set("image", asset => (
  <Picture
    title={asset.fields.title}
    description={asset.fields.description}
    url={asset.fields.file.url}
  />
));

// Type guard for asset hyperlink which is not directly defined in Contentful
function isAssetHyperlink(node: Node | Inline): node is Inline {
  return node.nodeType === INLINES.ASSET_HYPERLINK;
}

function getRichTextOptions(locale: string, isNasinneula: boolean): Options {
  const richTextOptions: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: Node | Inline): ReactNode => {
        const asset: Asset = node.data.target;
        const mimeGroup: string =
          asset?.fields?.file?.contentType?.split("/")[0];
        const mimeHandler = mimeHandlers.get(mimeGroup);
        return mimeHandler && asset ? mimeHandler(asset) : null;
      },
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: ({ data, content }): ReactNode => {
        const href = data.uri;
        const linkText =
          content.length && content[0].nodeType === "text"
            ? content[0].value
            : href;
        return (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>
        );
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ENTRY_HYPERLINK]: ({ data, content }): ReactNode => {
        const title =
          content.length && content[0].nodeType === "text"
            ? content[0].value
            : data.target?.fields?.title;
        const slug = buildEntryLink(data.target);
        return <a href={`/${locale}/${slug}`}>{title}</a>;
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ASSET_HYPERLINK]: (node: Node | InlineText): ReactNode => {
        if (isAssetHyperlink(node)) {
          const asset: Asset = node.data.target;
          const content = node.content[0];
          if (content.nodeType === "text") {
            return (
              <a href={`https:${asset.fields.file.url}`}>{content.value}</a>
            );
          }
        }
        return <></>;
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }): ReactNode => {
        if (data.target?.sys?.contentType?.sys?.id === "contentModuleButton") {
          const { link } = data.target.fields as IContentModuleButtonFields;

          return (
            <Button
              title=""
              link={link}
              cssClass={isNasinneula ? "nn" : undefined}
              primary
            />
          );
        }
        return null;
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (_, children): ReactNode => {
        return <p className="ws-pre-line">{children}</p>;
      },
    },
  };

  const richTextOptionsNasinneula: Options = {
    ...richTextOptions,
    renderNode: {
      ...richTextOptions.renderNode,
      // eslint-disable-next-line react/display-name
      [BLOCKS.HEADING_2]: (_, children): ReactNode => {
        return <h2 className="tracked-5 nn">{children}</h2>;
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (_, children): ReactNode => {
        return <p className="tracked-10 ws-pre-line">{children}</p>;
      },
    },
  };

  if (isNasinneula) {
    return richTextOptionsNasinneula;
  }

  return richTextOptions;
}

const RichText: React.FC<IContentModuleRichTextFields> = ({
  content,
  contentVerticalAlignment,
  horizontalPadding,
  textAlignment,
  verticalPadding,
}) => {
  const { isNasinneula, styles } = useContext(ThemeContext);
  const { locale } = useIntl();

  if (!content) {
    return <></>;
  }

  const options = getRichTextOptions(locale, isNasinneula);

  const renderedContent = documentToReactComponents(content, options);

  return (
    <article
      className={classNames("h-100", "mw7", "flex", "justify-center", {
        "flex-column": contentVerticalAlignment === "center",
        "flex-row": contentVerticalAlignment !== "center",
        "items-center": contentVerticalAlignment === "center",
        "items-end": contentVerticalAlignment === "bottom",
        "items-start": contentVerticalAlignment === "top",
        ph3: horizontalPadding === "normal",
        ph5: horizontalPadding === "big",
        pv3: verticalPadding === "normal",
        pv5: verticalPadding === "big",
      })}
      data-testid="rich-text-container"
    >
      <div
        className={classNames(
          "nested-contained-copy-separator",
          "nested-headline-line-height",
          "nested-links",
          "nested-heading-font-sizes",
          "nested-contentful-rich-text-list",
          "nested-list",
          styles.paragraph3,
          {
            tl: textAlignment === "left",
            tc: textAlignment === "center",
            tr: textAlignment === "right",
          },
        )}
        data-testid="rich-text-content"
      >
        {renderedContent}
      </div>
    </article>
  );
};

export default RichText;

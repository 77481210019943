import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebouncedCallback } from "use-debounce";

import IconArrowLeft from "../../../public/svg/icon-arrow-left.svg";
import {
  IAttraction,
  IAttractionCategoryFields,
  IContentModuleRideSearchFields,
} from "../../types/generated/contentful";
import { heading2, textButton } from "../../utils/styles";
import RideSearchFilters from "../RideSearchFilters";
import RideSearchList from "../RideSearchList";
import RideSearchSingleRide from "../RideSearchSingleRide";
import RideSearchViewToggle from "../RideSearchViewToggle";

export enum View {
  LIST = "list",
  SEARCH = "search",
}

export enum StorageKey {
  VIEW = "ride-search-view",
  CATEGORIES = "ride-search-categories",
  HEIGHT = "ride-search-height",
}

const RideSearch: React.FC<IContentModuleRideSearchFields> = ({
  rides,
  filterHeightRestrictions,
  filterCategories,
}) => {
  const { formatMessage } = useIntl();
  const mobileFilters = useRef(null);
  const [bodyScrollTarget, setBodyScrollTarget] = useState<Element | null>(
    null,
  );
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [view, setView] = useState<View>(View.SEARCH);
  const [height, setHeight] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const activeFilters = !!height || !!categories.length;

  const resetFilters = useCallback((): void => {
    setHeight("");
    setCategories([]);

    if (sessionStorage) {
      sessionStorage.removeItem(StorageKey.HEIGHT);
      sessionStorage.removeItem(StorageKey.CATEGORIES);
    }
  }, []);

  const closeFilters = useCallback((): void => {
    setFiltersOpen(false);

    if (bodyScrollTarget) {
      enableBodyScroll(bodyScrollTarget);
    }
  }, [bodyScrollTarget]);

  const openFilters = useCallback((): void => {
    setFiltersOpen(true);

    if (bodyScrollTarget) {
      disableBodyScroll(bodyScrollTarget);
    }
  }, [bodyScrollTarget]);

  const toggleFilters = useCallback((): void => {
    filtersOpen ? closeFilters() : openFilters();
  }, [closeFilters, openFilters, filtersOpen]);

  const handleViewChange = useCallback((view: View) => {
    setView(view);

    if (sessionStorage) {
      sessionStorage.setItem(StorageKey.VIEW, view);
    }
  }, []);

  const windowResize = useDebouncedCallback(() => {
    if (filtersOpen && window.innerWidth >= 1200) {
      closeFilters();
    }
  }, 250);

  const matchesHeightFilter = useCallback(
    ({
      fields: { heightRestriction, heightRestrictionWithAdult },
    }: IAttraction) => {
      return (
        heightRestrictionWithAdult?.some(
          heightRestriction => heightRestriction.fields.name === height,
        ) ||
        heightRestriction?.some(
          heightRestriction => heightRestriction.fields.name === height,
        )
      );
    },
    [height],
  );

  const matchesCategoriesFilter = useCallback(
    ({ fields: { attractionCategory, isAccessible } }: IAttraction) => {
      const accessibleCategoryFields: IAttractionCategoryFields = {
        name: formatMessage({ id: "Accessible" }),
        title: "Saavutettava",
      };
      const attractionCategoryFields =
        attractionCategory?.map(({ fields }) => fields) ?? [];
      const attractionCategories = isAccessible
        ? [...attractionCategoryFields, accessibleCategoryFields]
        : attractionCategoryFields;
      return (
        !attractionCategory ||
        categories.some(category =>
          attractionCategories.map(({ name }) => name).includes(category),
        )
      );
    },
    [categories, formatMessage],
  );

  const ridesToDisplay = useMemo(() => {
    return activeFilters
      ? rides.filter(ride => {
          if (height !== "" && !matchesHeightFilter(ride)) {
            return false;
          }

          if (categories.length && !matchesCategoriesFilter(ride)) {
            return false;
          }

          return true;
        })
      : rides;
  }, [
    activeFilters,
    categories,
    height,
    matchesCategoriesFilter,
    matchesHeightFilter,
    rides,
  ]);

  useEffect(() => {
    if (sessionStorage) {
      const height = sessionStorage.getItem(StorageKey.HEIGHT);
      const categories = sessionStorage
        .getItem(StorageKey.CATEGORIES)
        ?.split(",");

      if (height) {
        setHeight(height);
      }

      if (categories?.length) {
        setCategories(categories);
      }
    }
  }, []);

  useEffect(() => {
    setBodyScrollTarget(mobileFilters.current);

    return (): void => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", windowResize);

    return (): void => {
      window.removeEventListener("resize", windowResize);
    };
  }, [windowResize]);

  return (
    <section>
      <RideSearchViewToggle view={view} handleViewChange={handleViewChange} />
      {view === "search" ? (
        <>
          <div
            className={`fixed top-0 left-0 w-100 h-100 pv3 bg-white z-max cover momentum-scrolling ${
              filtersOpen ? "db" : "dn"
            } db-l static-l h-auto-l w-auto-l overflow-auto`}
            data-testid="ride-search-mobile-filters"
            ref={mobileFilters}
          >
            <div className="flex justify-between items-center mb3 dn-l">
              <div className="">
                <button
                  className="icon-wrapper-large svg-icon stroke-current-color fill-transparent bw0 bg-transparent pointer hover-pink ph3"
                  aria-label={formatMessage({ id: "rideSearch.closeFilters" })}
                  onClick={closeFilters}
                  data-testid="ride-search-mobile-filters-close"
                >
                  <IconArrowLeft />
                </button>
              </div>
              {activeFilters && (
                <div className="">
                  <button
                    className={`${textButton} hover-black ph3`}
                    onClick={resetFilters}
                  >
                    <FormattedMessage id="rideSearch.clearAllFilters" />
                  </button>
                </div>
              )}
            </div>
            <RideSearchFilters
              filterHeightRestrictions={filterHeightRestrictions}
              filterCategories={filterCategories}
              height={height}
              categories={categories}
              activeFilters={activeFilters}
              setHeight={setHeight}
              setCategories={setCategories}
              resetFilters={resetFilters}
            />
          </div>
          <div className="tc mb3 flex justify-between ph3 mv4 mt5-l justify-center-l">
            <h2 className={`${heading2()} ma0`}>
              {activeFilters ? (
                ridesToDisplay.length ? (
                  <FormattedMessage
                    id="rideSearch.foundResults"
                    values={{ value: ridesToDisplay.length }}
                  />
                ) : (
                  <FormattedMessage id="rideSearch.noFoundResults" />
                )
              ) : (
                <FormattedMessage id="rideSearch.allRides" />
              )}
            </h2>
            <button
              className={`${textButton} hover-black dn-l`}
              onClick={toggleFilters}
              data-testid="ride-search-mobile-filters-open"
            >
              <FormattedMessage id="rideSearch.filters" />
            </button>
          </div>
          <div className="ph3 mb4">
            <div className="flex flex-wrap items-stretch justify-center justify-start-ns na2">
              {ridesToDisplay.map(ride => (
                <div
                  key={ride.fields.slug}
                  className="w-100 mw6 mw-100-ns w-50-ns w-third-l pa2"
                  data-testid={`ride-search-${ride.fields.slug}`}
                >
                  <RideSearchSingleRide ride={ride} />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="mv4 ph3">
          <RideSearchList
            rides={rides}
            heightRestrictions={filterHeightRestrictions}
          />
        </div>
      )}
    </section>
  );
};

export default RideSearch;

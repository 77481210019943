import classNames from "classnames";
import React, { useCallback, useContext, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import BackgroundWavesSmall from "../../public/svg/background-waves-small.svg";
import IconArrowRightThin from "../../public/svg/icon-arrow-right-thin.svg";
import { ThemeContext } from "../contexts/ThemeContext";
import aspectRatioPadding from "../utils/aspect-ratio-padding";

import css from "./SocialMediaWallItem.module.scss";

export type Source = "Instagram" | "Facebook" | "Twitter" | "YouTube";

export interface SocialMediaWallItemProps {
  altText: string | null;
  coverUrl: string;
  hideText: boolean;
  id: number;
  index: number;
  link: string | null;
  source: Source | null;
  text: string | null;
  title: string;
  profileURL: string | null;
  profilePictureURL: string | null;
}

function linkify(
  inputText: string,
  isNasinneula: boolean,
  source: Source | null,
): string {
  let outputText;
  let hashTagBaseURL: string | null;
  let mentionBaseURL: string | null;

  switch (source) {
    case "Instagram":
      hashTagBaseURL = "https://instagram.com/explore/tags/";
      mentionBaseURL = "https://instagram.com/";
      break;
    case "Facebook":
      hashTagBaseURL = null;
      mentionBaseURL = "https://facebook.com/";
      break;
    case "Twitter":
      hashTagBaseURL = "https://twitter.com/search?q=%23";
      mentionBaseURL = "https://twitter.com/";
      break;
    case "YouTube":
      hashTagBaseURL = null;
      mentionBaseURL = null;
      break;
    default:
      hashTagBaseURL = null;
      mentionBaseURL = null;
      break;
  }

  const linkClass = isNasinneula
    ? "fw5 black no-underline"
    : "purple underline";
  const linkTarget = "_blank";
  const linkRel = "noopener noreferrer";

  // URLs starting with http:// or https://
  const protocolPattern =
    /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  outputText = inputText.replace(
    protocolPattern,
    `<a class="${linkClass}" href="$1" target="${linkTarget}" rel="${linkRel}">$1</a>`,
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const wwwPattern = /(^|[^/])(www\.[\S]+[^(.|,|?|!|:)\s](\b|$))/gim;
  outputText = outputText.replace(
    wwwPattern,
    `$1<a class="${linkClass}" href="https://$2" target="${linkTarget}" rel="${linkRel}">$2</a>`,
  );

  if (hashTagBaseURL) {
    // Change hashtags (#) to links pointing to hashtag page
    const hashTagPattern = /(\s)#(\S+[^(.|,|?|!|:)\s])/g;
    outputText = outputText.replace(
      hashTagPattern,
      `$1<a class="${linkClass}" href="${hashTagBaseURL}$2" target="${linkTarget}" rel="${linkRel}">#$2</a>`,
    );
  }

  if (mentionBaseURL) {
    // Change mentions (@) to links pointing to profile page
    const mentionPattern = /(\s)@(\S+[^(.|,|?|!|:)\s])/g;
    outputText = outputText.replace(
      mentionPattern,
      `$1<a class="${linkClass}" href="${mentionBaseURL}$2" target="${linkTarget}" rel="${linkRel}">@$2</a>`,
    );
  }

  return outputText;
}

function getBackgroundClass(
  isNasinneula: boolean,
  isOdd: boolean,
): string | undefined {
  if (isNasinneula) {
    return "bg-near-white";
  }

  return isOdd ? "bg-light-pink" : "bg-light-turquoise";
}

function getShowAll(str: string | null): boolean {
  if (str === null) {
    return true;
  }
  // Replace surrogate pairs with "_".
  // This helps figuring out the approximate length of string that has emojis in it.
  return str.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "_").length <= 300;
}

export const dimensions = {
  textLineHeight: 1.3,
  partialTextContainerHeight: 17,
  partialTextRowCount: 9,
  showMoreContainerHeight: 3,
  get partialTextHeight(): number {
    return this.partialTextRowCount * this.textLineHeight;
  },
};

export const SocialMediaWallItem: React.FC<SocialMediaWallItemProps> = ({
  altText,
  coverUrl,
  hideText,
  index,
  link,
  profileURL,
  profilePictureURL,
  text,
  title,
  source,
}) => {
  const { isNasinneula } = useContext(ThemeContext);
  const [showAll, setShowAll] = useState(getShowAll(text));
  const isOdd = index % 2 !== 0;
  const bgClass = getBackgroundClass(isNasinneula, isOdd);

  const [retryCount, setRetryCount] = useState(0);
  const coverImageRef = useRef<HTMLImageElement>(null);
  const onImageError = useCallback(() => {
    if (coverImageRef.current && retryCount < 2) {
      coverImageRef.current.src = coverUrl;
      setRetryCount(prev => prev + 1);
    }
  }, [coverImageRef, coverUrl, retryCount]);
  const {
    textLineHeight,
    partialTextContainerHeight,
    showMoreContainerHeight,
    partialTextHeight,
  } = dimensions;
  const coverImageElement = (
    <picture>
      <img
        ref={coverImageRef}
        src={coverUrl}
        loading="lazy"
        alt={altText || title}
        onError={onImageError}
        className="w-100"
        data-testid="social-media-wall-item-image"
      />
    </picture>
  );

  const onShowMoreClick = useCallback(() => {
    setShowAll(true);
  }, []);

  return (
    <div className="pa2 relative">
      <div
        className="relative overflow-hidden"
        data-testid="social-media-wall-item"
      >
        {link ? (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="social-media-wall-item-image-link"
          >
            {coverImageElement}
          </a>
        ) : (
          coverImageElement
        )}
        {!hideText && text && (
          <div
            className={classNames("pa3", "relative", bgClass, {
              pb4: showAll,
            })}
            style={{
              height: showAll ? "auto" : `${partialTextContainerHeight}rem`,
              overflow: showAll ? "visible" : "hidden",
            }}
            data-testid="social-media-wall-item-text-container"
          >
            {source && profileURL && (
              <a
                href={profileURL}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center mb3 no-underline black"
                data-testid="social-media-wall-item-source"
              >
                {profilePictureURL && (
                  <picture>
                    <img
                      src={profilePictureURL}
                      alt="Profile"
                      className="br-100"
                      loading="lazy"
                      width="40"
                      height="40"
                      data-testid="social-media-wall-item-source-logo"
                    />
                  </picture>
                )}
                <span className="ml2">{source}</span>
              </a>
            )}
            <p
              dangerouslySetInnerHTML={{
                __html: linkify(text, isNasinneula, source),
              }}
              className="break-word db ma0"
              style={{
                lineHeight: `${textLineHeight + 0.05}rem`,
                height: !showAll ? `${partialTextHeight}rem` : "auto",
                overflow: !showAll ? "hidden" : "visible",
              }}
              data-testid="social-media-wall-item-text"
            />
          </div>
        )}
        {!hideText && !showAll && (
          <div
            className={classNames("ph3", "pb4", bgClass)}
            data-testid="social-media-wall-item-show-more-container"
            style={{ height: `${showMoreContainerHeight}rem` }}
          >
            <a
              href={undefined}
              className={classNames(
                css.showMore,
                "pointer",
                "ttu",
                "no-underline",
                "fill-transparent",
                "stroke-current-color",
                "flex",
                "items-center",
                "tracked-5",
                "black",
              )}
              onClick={onShowMoreClick}
              data-testid="social-media-wall-item-show-more-button"
            >
              <FormattedMessage id="socialMediaWall.showMore" />
              <IconArrowRightThin />
            </a>
          </div>
        )}
        {!isNasinneula && (
          <div
            className={classNames(
              "wave",
              "w-100",
              "bg-white",
              css.waveBottom,
              "db",
              bgClass,
              "white",
              "relative",
              "pt4",
            )}
            style={{ paddingTop: aspectRatioPadding(335, 33) }}
          >
            <BackgroundWavesSmall />
          </div>
        )}
      </div>
    </div>
  );
};

type HTMLEntity = "&shy;";
type HTMLEntities = Record<HTMLEntity, string>;

export const SOFT_HYPHEN: HTMLEntity = "&shy;";

export const supportedHTMLEntities: HTMLEntities = {
  [SOFT_HYPHEN]: "\u00AD",
};

export function replaceAll(
  text: string,
  search: string,
  replaceWith: string,
): string {
  return text.split(search).join(replaceWith);
}

function replaceHTMLEntitiesFromText(text: string): string {
  let replacedText = text;
  Object.entries(supportedHTMLEntities).forEach(([key, value]) => {
    replacedText = replaceAll(replacedText, key, value);
  });
  return replacedText;
}

export default replaceHTMLEntitiesFromText;

import classNames from "classnames";
import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { IContentModuleLogoCollectionFields } from "../../types/generated/contentful";
import px2rem from "../../utils/px2rem";

import Logo from "./Logo";

const LogoCollection: React.FC<IContentModuleLogoCollectionFields> = ({
  title,
  images,
}) => {
  const { styles } = useContext(ThemeContext);

  return (
    <div className="ph3">
      <div>
        <h2 className={classNames(styles.headingHero, "tc mt0 mb5")}>
          {title}
        </h2>
      </div>
      <ul className="list ma0 pa0 flex flex-wrap items-center justify-center">
        {images.map((image, index) => (
          <li
            className="mb4 mh4 flex items-center"
            style={{ height: px2rem(96) }}
            key={index}
          >
            <Logo
              linkLocation={image.fields.linkLocation}
              image={image.fields.image}
              cssClass={"w-100 h-100 ofi-contain"}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LogoCollection;

import classNames from "classnames";
import React, { useCallback, useContext, useState } from "react";
import Modal from "react-modal";

import IconArrowRightThin from "../../../public/svg/icon-arrow-right-thin.svg";
import { ThemeContext } from "../../contexts/ThemeContext";
import { IContentModuleGridModalFields } from "../../types/generated/contentful";
import { heading1 } from "../../utils/styles";
import Picture from "../Picture";

import ContentModules from "./ContentModules";

const GridModal: React.FC<IContentModuleGridModalFields> = ({
  backgroundImage,
  content,
  heading,
}) => {
  const [open, setOpen] = useState(false);
  const { isNasinneula, isSarkanniemi } = useContext(ThemeContext);

  const onHeadingClick = useCallback(() => {
    setOpen(true);
  }, []);

  const onCloseClick = useCallback(() => {
    setOpen(false);
  }, []);

  const backgroundImageElement = (
    <Picture
      cssClass="aspect-ratio--object ofi-cover"
      title={backgroundImage.fields.title}
      description={backgroundImage.fields.description}
      url={backgroundImage.fields.file.url}
    />
  );

  return (
    <div className="relative flex items-center">
      <div className="w-100 h-100">
        <div
          className="aspect-ratio aspect-ratio--9x16-l aspect-ratio--16x9"
          data-testid="grid-modal-background-image-container"
        >
          {backgroundImageElement}
        </div>
      </div>
      <div className="absolute z-1 w-100 tc">
        <button
          className={classNames(
            "b--none",
            "bg-transparent",
            "pointer",
            heading1(isNasinneula),
            {
              white: isNasinneula,
            },
          )}
          onClick={onHeadingClick}
          data-testid="grid-modal-heading-button"
        >
          {heading}
        </button>
      </div>

      <Modal
        isOpen={open}
        style={{
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: 0,
            outline: 0,
            borderRadius: 0,
            border: 0,
          },
        }}
      >
        <div
          className="relative flex items-center h-100 w-100"
          data-testid="grid-modal-modal-container"
        >
          <div className="w-100 h-100 fixed z-2">
            <div
              className="aspect-ratio w-100 h-100"
              data-testid="grid-modal-modal-background-image-container"
            >
              {backgroundImageElement}
            </div>
          </div>
          <div className="relative z-3 w-100 h-100 overflow-auto">
            <button
              className={classNames(
                "fixed",
                "fill-transparent",
                "b--none",
                "bg-transparent",
                "pointer",
                "pa3",
                {
                  "stroke-white": isNasinneula,
                  "stroke-current-color": isSarkanniemi,
                },
              )}
              onClick={onCloseClick}
              style={{ transform: "rotateY(180deg)" }}
              data-testid="grid-modal-modal-close-button"
            >
              <IconArrowRightThin style={{ width: "3rem", height: "3rem" }} />
            </button>
            <div
              className={classNames({
                tc: isNasinneula,
                white: isNasinneula,
              })}
              data-testid="grid-modal-modal-content-container"
            >
              <h2
                className={classNames("mt3", heading1(isNasinneula))}
                data-testid="grid-modal-modal-heading"
              >
                {heading}
              </h2>
              <ContentModules contentModules={content} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GridModal;

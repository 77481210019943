import React from "react";

import px2rem from "../utils/px2rem";

interface IconProps {
  className?: string;
  icon: React.ReactNode;
  size: number;
}

const Icon: React.FC<IconProps> = ({ className, icon, size }) => {
  return (
    <span
      className={className}
      style={{
        width: px2rem(size),
        height: px2rem(size),
      }}
      data-testid="icon-container"
    >
      {icon}
    </span>
  );
};

export default Icon;

import React, { useCallback, useState } from "react";

import { imageManipulation } from "../../lib/contentful/image";
import { IContentModuleImageGalleryFields } from "../../types/generated/contentful";
import { heading1 } from "../../utils/styles";
import PhotoSwipeWrapper from "../PhotoSwipeWrapper";
import Picture from "../Picture";

const ImageGallery: React.FC<IContentModuleImageGalleryFields> = ({
  images,
  heading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const items = images.map(image => {
    return {
      src: imageManipulation({ url: image.fields.file.url, width: 2560 }),
      w: typeof window !== "undefined" ? window.innerWidth : 0,
      h: typeof window !== "undefined" ? window.innerHeight : 0,
      title: image.fields.description,
    };
  });

  const handleOpen = useCallback((event: React.MouseEvent): void => {
    setIsOpen(true);
    setIndex(parseInt(event.currentTarget.id));
  }, []);

  const handleClose = useCallback((): void => {
    setIsOpen(false);
  }, []);

  return (
    <div className="ph3 mv4 mv5-ns">
      {heading && (
        <h2
          className={`${heading1()} underline mt0 mb4`}
          data-testid="image-gallery-heading"
        >
          {heading}
        </h2>
      )}
      <div className="flex flex-wrap justify-center na2">
        {images.map((image, index) => (
          <div className="w-50 w-third-l pa2" key={image.sys.id}>
            <button
              className="pointer bg-transparent bw0 pt0 ph0 w-100 h-100 aspect-ratio aspect-ratio--3x4"
              id={index.toString()}
              onClick={handleOpen}
              data-testid="image-gallery-open-button"
            >
              <Picture
                title={image.fields.title}
                description={image.fields.description}
                url={image.fields.file.url}
                cssClass="aspect-ratio--object ofi-cover"
              />
            </button>
          </div>
        ))}
      </div>
      <PhotoSwipeWrapper
        isOpen={isOpen}
        index={index}
        items={items}
        onClose={handleClose}
      />
    </div>
  );
};

export default ImageGallery;

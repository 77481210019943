import classNames from "classnames";
import React, { useContext } from "react";

import BackgroundWavesHorizontal from "../../../public/svg/background-waves-horizontal.svg";
import BackgroundWavesMobile from "../../../public/svg/background-waves-mobile.svg";
import { ThemeContext } from "../../contexts/ThemeContext";
import { getBackgroundColor } from "../../lib/contentful/colors";
import replaceHTMLEntitiesFromText from "../../lib/replace-html-entities";
import { IContentModulePageHeroFields } from "../../types/generated/contentful";
import aspectRatioPadding from "../../utils/aspect-ratio-padding";
import Picture from "../Picture";

import Button from "./Button";
import css from "./PageHero.module.scss";

const PageHero: React.FC<IContentModulePageHeroFields> = ({
  heading,
  links,
  description,
  backgroundImage,
  backgroundColor: _backgroundColor,
  backgroundColorV2,
}) => {
  const { styles } = useContext(ThemeContext);

  const { backgroundClassName, backgroundColor } = getBackgroundColor({
    backgroundColor: _backgroundColor,
    backgroundColorV2,
  });

  return (
    <header
      className={classNames(backgroundClassName && `bg-${backgroundClassName}`)}
      style={{ backgroundColor }}
    >
      <div className="h-auto-ns relative flex-ns items-stretch justify-center">
        <div
          className={classNames(
            `vh-75 h-auto-ns w-100 w-50-ns relative`,
            backgroundClassName,
          )}
          style={{ color: backgroundColor }}
        >
          {backgroundImage.fields && (
            <Picture
              title={backgroundImage.fields.title}
              description={backgroundImage.fields.description}
              url={backgroundImage.fields.file.url}
              cssClass="absolute top-0 left-0 w-100 h-100 ofi-cover"
            />
          )}
          <div
            className="absolute bottom-0 left-0 w-100 dn-ns"
            style={{ paddingTop: aspectRatioPadding(320, 30) }}
            aria-hidden="true"
          >
            <div className={css.overlay}>
              <BackgroundWavesMobile />
            </div>
          </div>
          <div
            className="absolute top-0 right-0 w-100 h-100 translate-right-50 dn db-ns"
            style={{}}
            aria-hidden="true"
          >
            <div className={classNames(css.overlay, css.overlayDesktop)}>
              <BackgroundWavesHorizontal />
            </div>
          </div>
        </div>
        <div className="relative w-100 w-50-ns flex flex-column justify-center ph3 pv4 pa5-ns">
          <h1
            className={`${styles.headingHero} mt0 mb4 hyphens-manual`}
            data-testid="page-hero-heading"
          >
            {replaceHTMLEntitiesFromText(heading)}
          </h1>
          <div
            className={`${styles.paragraph3} nested-contained-copy-separator`}
          >
            <p>{description}</p>
          </div>
          {links?.map((link, index) => (
            <div
              className={index === 0 ? "mt4 pt2 pt3-l" : "mt3"}
              key={link.sys.id}
            >
              <Button title="" link={link} primary />
            </div>
          ))}
        </div>
      </div>
    </header>
  );
};

export default PageHero;

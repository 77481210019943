import classNames from "classnames";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

import IconAccessible from "../../public/svg/icon-accessible.svg";
import { ThemeContext } from "../contexts/ThemeContext";
import {
  IAttraction,
  IAttractionHeightRestriction,
} from "../types/generated/contentful";

import css from "./RideSearchList.module.scss";

interface RideSearchListProps {
  rides: IAttraction[] | undefined;
  heightRestrictions: IAttractionHeightRestriction[] | undefined;
}

const RideSearchList: React.FC<RideSearchListProps> = ({
  rides,
  heightRestrictions,
}) => {
  const { styles } = useContext(ThemeContext);
  return (
    <div>
      <ul className="list ma0 pa0 flex items-center justify-center">
        <li className="flex items-center">
          <span
            className="w1 h1 bg-light-turquoise br-100 dib flex-shrink-0"
            aria-hidden="true"
          ></span>
          <span className={`${styles.paragraph3} ml2`}>
            <FormattedMessage id="rideSearch.canGoYourself" />
          </span>
        </li>
        <li className="flex items-center ml3">
          <span
            className="w1 h1 bg-light-pink br-100 dib flex-shrink-0"
            aria-hidden="true"
          ></span>
          <span className={`${styles.paragraph3} ml2`}>
            <FormattedMessage id="rideSearch.canGoWithAdult" />
          </span>
        </li>
        <li className="flex items-center ml3">
          <span
            className="w1 h1 bg-moon-gray br-100 dib flex-shrink-0"
            aria-hidden="true"
          ></span>
          <span className={`${styles.paragraph3} ml2`}>
            <FormattedMessage id="rideSearch.unavailable" />
          </span>
        </li>
      </ul>
      <table className="mv4 mw7 w-100 center">
        <thead data-testid="ride-search-list-table-head">
          <tr>
            <td className="pv3 v-btm">
              <span className="clip">
                <FormattedMessage id="rideSearch.isAccessible" />
              </span>
            </td>
            <td className="pv3 v-btm">
              <span className="clip">
                <FormattedMessage id="rideSearch.rideName" />
              </span>
            </td>
            {heightRestrictions?.map(heightRestriction => (
              <td
                key={heightRestriction.fields.name}
                className="pv3 v-btm nowrap"
              >
                <span className={classNames(styles.paragraph2, css.title)}>
                  {heightRestriction.fields.name}
                </span>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {rides?.map(ride => (
            <tr
              key={ride.fields.slug}
              data-testid={`ride-search-list-table-item-${ride.fields.slug}`}
            >
              <td className="pv2 pr2 svg-icon">
                {ride.fields.isAccessible && (
                  <>
                    <IconAccessible />
                    <span className="clip">
                      <FormattedMessage id="rideSearch.accessible" />
                    </span>
                  </>
                )}
              </td>
              <td className="pv2 pr2">
                <span className={`${styles.heading3}`}>
                  {ride.fields.title}
                </span>
              </td>
              {heightRestrictions?.map(heightRestriction => {
                let color;
                let string;

                if (
                  ride.fields.heightRestriction?.some(
                    restriction =>
                      restriction.fields.name === heightRestriction.fields.name,
                  )
                ) {
                  color = "light-turquoise";
                  string = "canGoYourself";
                } else if (
                  ride.fields.heightRestrictionWithAdult
                    ?.map(height => height.fields.name)
                    .includes(heightRestriction.fields.name)
                ) {
                  color = "light-pink";
                  string = "canGoWithAdult";
                } else {
                  color = "moon-gray";
                  string = "unavailable";
                }

                return (
                  <td
                    key={`${ride.fields.title}-${heightRestriction.fields.name}`}
                    className="pv2 pr2"
                  >
                    <span
                      className={`w1 h1 br-100 dib bg-${color}`}
                      aria-hidden="true"
                    ></span>
                    <span className="clip">
                      <span>{heightRestriction.fields.name}</span>
                      <FormattedMessage id={`rideSearch.${string}`} />
                    </span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RideSearchList;

import classNames from "classnames";
import { NextSeo } from "next-seo";
import React, { useContext } from "react";

import ContentfulPageProvider from "../contexts/ContentfulPageContext";
import { ThemeContext } from "../contexts/ThemeContext";
import { isEntryPage, PageLikeEntry } from "../lib/contentful";
import { getNextSeoConfigForContentfulPage } from "../seo/seo";

import ContentModules from "./contentful/ContentModules";
import Layout from "./layouts/Default";

interface ContentfulPageProps {
  entry: PageLikeEntry;
}

const ContentfulPage: React.FC<ContentfulPageProps> = ({ entry }) => {
  const { styles } = useContext(ThemeContext);

  return (
    <ContentfulPageProvider entry={entry}>
      <NextSeo {...getNextSeoConfigForContentfulPage(entry)} />
      <Layout>
        <article>
          {isEntryPage(entry) && entry.fields.showTitle && (
            <div className="ph3 pt4">
              <h1 className={classNames(styles.headingHero, "ma0")}>
                {entry.fields.title}
              </h1>
            </div>
          )}
          {entry.fields.contentModules && (
            <ContentModules contentModules={entry.fields.contentModules} />
          )}
        </article>
      </Layout>
    </ContentfulPageProvider>
  );
};

export default ContentfulPage;

import classnames from "classnames";
import React from "react";

import { IContentModuleTableFields } from "../../types/generated/contentful";
import { paragraph2 } from "../../utils/styles";

const Table: React.FC<IContentModuleTableFields> = ({ tableRows }) => (
  <table className="mw7 ph3 center w-100 mv4 mv5-ns">
    <tbody className={`${paragraph2}`}>
      {tableRows.map((row, index) => (
        <tr
          key={index}
          className={classnames(
            "flex flex-wrap flex-column flex-row-ns justify-between-ns",
            { "bb b--near-white pb3 mb3": tableRows.length !== index + 1 },
          )}
        >
          <td className="mb2 mb0-ns" data-testid="table-row-heading">
            {row.fields.heading}
          </td>
          <td data-testid="table-row-value">{row.fields.value}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default Table;

import classNames from "classnames";
import React from "react";

import { IContentModuleGridFields } from "../../types/generated/contentful";

import ContentModules from "./ContentModules";

const Grid: React.FC<IContentModuleGridFields> = ({
  anchorLinkId,
  contentModules,
  gridColumns,
  gridColumnGap,
  margin,
  reverseColumnsOnMobile,
}) => {
  let columnWidth: string;

  switch (gridColumns) {
    case "2":
      columnWidth = "w-50-ns";
      break;

    case "3":
      columnWidth = "w-50-ns w-third-l";
      break;

    case "4":
      columnWidth = "w-50-ns w-25-l";
      break;
  }

  return (
    <div
      className={classNames({
        mt5: margin === "normal",
        ph3: gridColumnGap === "normal",
      })}
      id={anchorLinkId}
      data-testid="grid-container"
    >
      <div
        className={classNames("flex", "flex-wrap", "flex-row-ns", {
          "na-2": gridColumnGap === "normal",
          "flex-column-reverse": reverseColumnsOnMobile,
        })}
        data-testid="grid-column-container"
      >
        {contentModules?.map((contentModule, index) => (
          <div
            className={classNames(
              "mw6",
              "w-100",
              "mw-100-ns",
              "center",
              columnWidth,
              {
                mb4: gridColumnGap === "normal",
                pa2: gridColumnGap === "normal",
              },
            )}
            key={index}
            data-testid="grid-column"
          >
            <ContentModules contentModules={contentModule} className="h-100" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Grid;

import React from "react";

import { IContentModuleInfoContainerFields } from "../../types/generated/contentful";
import px2rem from "../../utils/px2rem";
import FlexSection from "../FlexSection";

import ContentModules from "./ContentModules";

const InfoContainer: React.FC<IContentModuleInfoContainerFields> = ({
  anchorLinkId,
  contentModules,
  heading,
}) => {
  return (
    <div className="ph3" id={anchorLinkId} data-testid="info-container">
      <FlexSection title={heading} split="40-60">
        {contentModules && <ContentModules contentModules={contentModules} />}
      </FlexSection>
      <style jsx>{`
        :global(.contentModuleInfoContainer .contentModuleRichText > article),
        :global(.contentModuleInfoContainer .contentModuleTable > table),
        :global(.contentModuleInfoContainer .contentModuleButton > div) {
          padding: 0;
          margin: 0;
          max-width: 100%;
        }

        :global(
            .contentModuleInfoContainer:not(:last-of-type) > div > section
          ) {
          border-bottom: 1px solid #feafd7 !important;
        }

        :global(.contentModuleInfoContainer section + section) {
          margin-top: ${px2rem(48)};
        }
      `}</style>
    </div>
  );
};

export default InfoContainer;

import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ThemeContext } from "../../contexts/ThemeContext";
import {
  BaseFlocklerArticle,
  FlocklerArticleFacebookPost,
  FlocklerArticleInstagram,
  FlocklerArticlesResponse,
  FlocklerArticleTweet,
  FlocklerArticleVideo,
} from "../../types/flockler";
import { IContentModuleSocialMediaWallFields } from "../../types/generated/contentful";
import MasonryGrid from "../MasonryGrid";
import {
  SocialMediaWallItem,
  SocialMediaWallItemProps,
} from "../SocialMediaWallItem";

function isFacebookPost(
  article: BaseFlocklerArticle,
): article is FlocklerArticleFacebookPost {
  return article.type === "facebook_post";
}

function isInstagram(
  article: BaseFlocklerArticle,
): article is FlocklerArticleInstagram {
  return article.type === "instagram";
}

function isTweet(
  article: BaseFlocklerArticle,
): article is FlocklerArticleTweet {
  return article.type === "tweet";
}

function isVideo(
  article: BaseFlocklerArticle,
): article is FlocklerArticleVideo {
  return article.type === "video";
}

interface NormalizedArticleFields {
  text: SocialMediaWallItemProps["text"];
  link: SocialMediaWallItemProps["link"];
  profileURL: SocialMediaWallItemProps["profileURL"];
  profilePictureURL: SocialMediaWallItemProps["profilePictureURL"];
  source: SocialMediaWallItemProps["source"];
}

/**
 * Normalizes article fields as Flockler attachments use differently named fields
 * for the same information.
 */
function normalizeArticleFields(
  article: BaseFlocklerArticle,
): NormalizedArticleFields {
  if (isFacebookPost(article)) {
    return {
      text: article.attachments.facebook_post.message,
      link: article.attachments.facebook_post.link,
      profileURL: `https://facebook.com/${article.attachments.facebook_post.from_id_str}`,
      profilePictureURL: null,
      source: "Facebook",
    };
  } else if (isInstagram(article)) {
    return {
      text: article.attachments.instagram_item.caption,
      link: article.attachments.instagram_item.link,
      profileURL: `https://instagram.com/${article.attachments.instagram_item.username}`,
      profilePictureURL: article.attachments.instagram_item.profile_picture,
      source: "Instagram",
    };
  } else if (isTweet(article)) {
    return {
      text: article.attachments.tweet.text,
      link: article.attachments.tweet.url,
      profileURL: article.attachments.tweet.profile_url,
      profilePictureURL: article.attachments.tweet.profile_image_url,
      source: "Twitter",
    };
  } else if (isVideo(article)) {
    return {
      text: null,
      link: article.attachments.video.embed_src,
      profileURL: `https://youtube.com/${article.attachments.video.username}`,
      profilePictureURL: null,
      source: "YouTube",
    };
  }
  return {
    text: null,
    link: null,
    profileURL: null,
    profilePictureURL: null,
    source: null,
  };
}

export const SocialMediaWall: React.FC<IContentModuleSocialMediaWallFields> = ({
  flocklerSectionId,
  flocklerSiteId,
  heading,
  hideText,
}) => {
  const { styles } = useContext(ThemeContext);
  const [error, setError] = useState(false);
  const [articles, setArticles] = useState<SocialMediaWallItemProps[]>([]);
  const [fetchMoreURL, setFetchMoreURL] = useState<string | null>(null);

  const handleArticlesResponse = useCallback(
    (response: Response) => {
      (response.json() as Promise<FlocklerArticlesResponse>).then(
        ({ articles, pagination }) => {
          const newArticles = articles.map((article, index) => {
            const normalizedFields = normalizeArticleFields(article);
            const props: SocialMediaWallItemProps = {
              id: article.id,
              title: article.title,
              altText: article.alt_text,
              coverUrl: article.cover_url,
              index,
              hideText,
              ...normalizedFields,
            };
            return props;
          });
          setArticles(articles => [...articles, ...newArticles]);
          setFetchMoreURL(pagination.older);
        },
      );
    },
    [hideText],
  );

  const handleErrorResponse = useCallback(() => {
    setError(true);
  }, []);

  useEffect(() => {
    const baseURL = "https://api.flockler.com/v1";
    const count = 8;
    const fields = [
      "alt_text",
      "attachments",
      "cover_url",
      "id",
      "title",
      "type",
    ].join(",");
    fetch(
      `${baseURL}/sites/${flocklerSiteId}/sections/${flocklerSectionId}/articles?count=${count}&sideloading=false&fields=${fields}`,
    )
      .then(handleArticlesResponse)
      .catch(handleErrorResponse);
  }, [
    flocklerSectionId,
    flocklerSiteId,
    hideText,
    handleArticlesResponse,
    handleErrorResponse,
  ]);

  const fetchMoreItems = useCallback(() => {
    if (fetchMoreURL) {
      fetch(fetchMoreURL)
        .then(handleArticlesResponse)
        .catch(handleErrorResponse);
    }
  }, [handleArticlesResponse, handleErrorResponse, fetchMoreURL]);

  const items = articles.map(article => (
    <SocialMediaWallItem key={article.id} {...article} />
  ));

  return (
    <div className="ph3 mv5 mv6-l">
      <h2
        className={classNames("tc", styles.heading1)}
        data-testid="social-media-wall-heading"
      >
        {heading}
      </h2>
      {error ? (
        <p className="tc" data-testid="social-media-wall-error-message">
          <FormattedMessage id="socialMediaWall.errorFetchingFeed" />
        </p>
      ) : (
        <>
          <MasonryGrid
            className="db dn-ns"
            initialVisibleCount={4}
            showMoreCount={4}
            items={items}
            canFetchMoreItems={!!fetchMoreURL}
            fetchMoreItems={fetchMoreItems}
          />
          <MasonryGrid
            className="dn db-ns"
            initialVisibleCount={8}
            showMoreCount={4}
            items={items}
            canFetchMoreItems={!!fetchMoreURL}
            fetchMoreItems={fetchMoreItems}
          />
        </>
      )}
    </div>
  );
};

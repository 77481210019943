import classNames from "classnames";
import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { IContentModuleContactCollectionFields } from "../../types/generated/contentful";
import { heading1 } from "../../utils/styles";

import Contact from "./Contact";

const ContactCollection: React.FC<IContentModuleContactCollectionFields> = ({
  heading,
  contacts,
}) => {
  const { isNasinneula, isSarkanniemi } = useContext(ThemeContext);
  return (
    <div className="mv4 mv5-ns ph3">
      <div>
        <h2
          className={classNames("mt0", "mb5", heading1(isNasinneula), {
            underline: isSarkanniemi,
          })}
        >
          {heading}
        </h2>
      </div>
      <div className="flex flex-wrap na3">
        {contacts.map((contact, i) => (
          <div
            className={`w-100 w-50-ns pa3 flex ${
              i % 2 === 0 ? "" : "justify-end justify-start-ns"
            }`}
            key={contact.fields.title}
            data-testid="contact-collection-contact"
          >
            <Contact {...contact.fields} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactCollection;

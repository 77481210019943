import classNames from "classnames";
import React, { useContext } from "react";

import DecorativeBorder from "../../../public/svg/decorative-border.svg";
import { ThemeContext } from "../../contexts/ThemeContext";
import { IContentModuleHighlightFields } from "../../types/generated/contentful";
import Picture from "../Picture";

import Button from "./Button";
import css from "./Highlight.module.scss";

interface ExtendedIContentModuleHighlightFields
  extends IContentModuleHighlightFields {
  isEven?: boolean;
  linkHoverColor?: string;
}

const Highlight: React.FC<ExtendedIContentModuleHighlightFields> = ({
  heading,
  description,
  link,
  image,
  isEven,
}) => {
  const { styles } = useContext(ThemeContext);

  return (
    <article
      className={`mw6 center mw-100-ns flex-ns items-center mt5 mt6-l ${
        isEven ? "flex-row-reverse-ns" : "pt4 nb4-ns"
      }`}
      data-testid="highlight"
    >
      <div className="w-80 center w-40-ns ph3-ns ph4-l">
        <div className="relative" style={{ paddingTop: `${(4 / 3) * 100}%` }}>
          <Picture
            title={image.fields.title}
            description={image.fields.description}
            url={image.fields.file.url}
            cssClass="absolute w-100 h-100 top-0 left-0 ofi-cover z-1"
          />
          <div
            className={classNames(
              `overflow-hidden absolute w-100 h-100 dn df-ns`,
              css.background,
              isEven ? css["background--even"] : css["background--odd"],
            )}
          >
            <DecorativeBorder />
          </div>
        </div>
      </div>
      <div
        className={`w-80 center mt0-l w-40-ns ph3-ns ph4-l ${
          !isEven ? "mt4" : "mt5"
        }`}
      >
        <h3 className={classNames(styles.heading2, "ma0")}>{heading}</h3>
        <div
          className={classNames(
            styles.paragraph3,
            "mt3 nested-contained-copy-separator",
          )}
        >
          <p>{description}</p>
        </div>
        <div className="mt4">
          <Button title="" link={link} primary />
        </div>
      </div>
    </article>
  );
};

export default Highlight;

import classNames from "classnames";
import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { IContentModuleHeroFields } from "../../types/generated/contentful";
import Picture from "../Picture";

import css from "./Hero.module.scss";
import Link from "./Link";

const Hero: React.FC<IContentModuleHeroFields> = ({
  heading,
  backgroundImage,
  backgroundVideo,
  link,
}) => {
  const { isNasinneula, isSarkanniemi, styles } = useContext(ThemeContext);
  const headings = new Array(20).fill(heading);

  const image = (
    <Picture
      title={backgroundImage.fields.title}
      description={backgroundImage.fields.description}
      url={backgroundImage.fields.file.url}
      cssClass="absolute top-0 left-0 w-100 h-100 ofi-cover"
    />
  );

  const video = backgroundVideo ? (
    <video
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
      poster={`${backgroundImage?.fields.file.url}?fm=jpg&q=80&w=1366`}
      className="absolute top-0 left-0 w-100 h-100 ofi-cover"
      data-testid="hero-video"
    >
      <source
        src={backgroundVideo.fields.file.url}
        type={backgroundVideo.fields.file.contentType}
      />
    </video>
  ) : null;

  const media = video ?? image;

  return (
    <header>
      <div
        className="vh-75 relative flex items-center justify-center"
        style={{ minHeight: "300px" }}
      >
        {link ? (
          <Link {...link.fields} title="">
            {media}
          </Link>
        ) : (
          media
        )}
        {heading && isNasinneula && (
          <div className="absolute">
            <h1
              className="nn white tracked-20 f-5 f-6-ns lh-title o-70 tc"
              data-testid="hero-nasinneula-heading"
            >
              {heading}
            </h1>
          </div>
        )}
      </div>
      {heading && isSarkanniemi && (
        <div className="relative pt3 overflow-hidden">
          <h1
            className={`${styles.headingHero} dib nowrap ma0 w-100 relative`}
            aria-label={heading}
            data-testid="hero-sarkanniemi-heading"
            style={{ lineHeight: 1.5 }}
          >
            <span
              className={classNames("db absolute", css.animationTicker)}
              aria-hidden="true"
            >
              <span
                className="ph3 ph4-l"
                dangerouslySetInnerHTML={{
                  __html: headings.join("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"),
                }}
                data-testid="hero-animation-ticker-text"
              />
            </span>
            <span style={{ visibility: "hidden" }}>{heading}</span>
          </h1>
        </div>
      )}
    </header>
  );
};

export default Hero;

import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { PageLike } from "../../lib/contentful";
import { buildEntryLink } from "../../lib/contentful/build-entry-link";
import {
  IContentModuleNavigationGroupFields,
  ILink,
} from "../../types/generated/contentful";
import px2rem from "../../utils/px2rem";
import ContentToggle from "../ContentToggle";
import LocaleLink from "../LocaleLink";
import Picture from "../Picture";

function getLocaleLinkProps(link: PageLike | ILink): {
  slug?: string;
  href?: string;
} {
  const { fields } = link;

  // Handle IPage link
  if ("slug" in fields) {
    return {
      slug: buildEntryLink(link as PageLike),
    };
  } else {
    const { anchorLink, internalLink, externalLink } = fields;

    // Handle ILink internal link
    if (internalLink) {
      const slug = buildEntryLink(internalLink);
      return anchorLink ? { slug: `${slug}#${anchorLink}` } : { slug };
    }

    // Handle ILink external link
    return anchorLink
      ? { href: `${externalLink}#${anchorLink}` }
      : { href: externalLink };
  }
}

function getLinkTitle({ fields }: PageLike | ILink): string {
  if ("slug" in fields) {
    return fields.title;
  }

  if (fields.internalLink) {
    return fields.linkTitle || fields.internalLink.fields.title;
  }
  if (fields.externalLink) {
    return fields.linkTitle || fields.externalLink;
  }

  return fields.title;
}

const NavigationGroup: React.FC<IContentModuleNavigationGroupFields> = ({
  image,
  links,
  useAccordionOnMobile,
}) => {
  const { styles } = useContext(ThemeContext);
  const firstLink = getLocaleLinkProps(links[0]);

  return (
    <article className="mw6 center">
      {image.fields && (
        <div
          className={`relative ${useAccordionOnMobile ? "dn db-ns" : ""}`}
          style={{ height: px2rem(416) }}
          data-testid="navigation-group-image-container"
        >
          <LocaleLink {...firstLink}>
            <a>
              <Picture
                title={image.fields.title}
                description={image.fields.description}
                url={image.fields.file.url}
                cssClass={"absolute top-0 left-0 w-100 h-100 ofi-cover"}
              />
            </a>
          </LocaleLink>
        </div>
      )}

      {useAccordionOnMobile && (
        <div className="db dn-ns" data-testid="navigation-group-mobile-links">
          <ContentToggle
            title={getLinkTitle(links[0])}
            titleClassName={styles.heading2}
          >
            <ul className="ma0 mt3 pa0 list">
              {links?.slice(1, links.length).map(link => {
                return (
                  <li key={getLinkTitle(link)} className="mb3 pb2">
                    <LocaleLink {...getLocaleLinkProps(link)}>
                      <a
                        className={`black no-underline flex items-center hover-pink svg-icon ${styles.paragraph1}`}
                      >
                        {getLinkTitle(link)}
                      </a>
                    </LocaleLink>
                  </li>
                );
              })}
            </ul>
          </ContentToggle>
        </div>
      )}

      <ul
        className={`ma0 mt3 pa0 list ${useAccordionOnMobile ? "dn db-ns" : ""}`}
        data-testid="navigation-group-non-mobile-links"
      >
        {links?.map((link, index) => {
          const isFirstLink = index === 0;

          return (
            <li
              className={`${index !== links.length && "mb3 pb2"}`}
              key={index}
            >
              <LocaleLink {...getLocaleLinkProps(link)}>
                <a
                  className={`black no-underline flex items-center underline-hover svg-icon ${
                    isFirstLink ? styles.heading2 : styles.paragraph1
                  }`}
                >
                  {getLinkTitle(link)}
                </a>
              </LocaleLink>
            </li>
          );
        })}
      </ul>
    </article>
  );
};

export default NavigationGroup;

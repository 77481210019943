import classNames from "classnames";
import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { IContentModuleImageFields } from "../../types/generated/contentful";
import Picture from "../Picture";

import Link from "./Link";

export const testIdHeading = "image-heading";
export const testIdContainer = "image-container";

const Image: React.FC<IContentModuleImageFields> = ({
  heading,
  image,
  aspectRatio,
  link,
}) => {
  const { styles } = useContext(ThemeContext);
  const imageToRender: JSX.Element = (
    <>
      {heading && (
        <h2
          className={`${styles.heading2} mt0 mb3`}
          data-testid={testIdHeading}
        >
          {heading}
        </h2>
      )}
      <div
        className={classNames("h-100", "aspect-ratio", {
          "aspect-ratio--1x1": aspectRatio === "1:1",
          "aspect-ratio--3x4": aspectRatio === "3:4",
          "aspect-ratio--16x9": aspectRatio === "16:9",
        })}
        data-testid={testIdContainer}
      >
        <Picture
          title={image.fields.title}
          description={image.fields.description}
          url={image.fields.file.url}
          cssClass="aspect-ratio--object ofi-cover"
        />
      </div>
    </>
  );

  return link ? (
    <Link {...link.fields} cssClass="color-inherit no-underline">
      {imageToRender}
    </Link>
  ) : (
    imageToRender
  );
};

export default Image;
